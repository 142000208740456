import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import DefaultDropdown from "../_shared/input/Dropdown";
import "../../index.css";
import { sendContact } from "../../_api/tool";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t, i18n } = useTranslation();

  const [objet, setObjet] = useState({ label: t("contact.object.account.creation") });
  const [content, setContent] = useState("");

  const [message, setMessage] = useState("");

  const sendMail = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("TOKEN");

    const payload = {
      label: objet.label,
      content: content,
    };

    sendContact(token, payload, i18n.language).then((resp) => {
      setObjet("");
      setContent("");
      setMessage(t("contact.success"));
    });
  };

  const options = [
    t("contact.object.account.creation"),
    t("contact.object.payroll.missing"),
    t("contact.object.profil.management"),
    t("contact.object.error.message"),
    t("contact.object.sponsor.help"),
    t("contact.object.other"),
  ];
  return (
    <Form className={"rounded border border-indigo-600 bg-white"}>
      <div className={"p-4"}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className={"font-semibold"}>{t("contact.object.title")}</Form.Label>
          <DefaultDropdown
            placeholder={t("contact.object.placeholder")}
            options={options}
            value={objet}
            onChange={setObjet}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicContent">
          <Form.Label className={"font-semibold"}>{t("contact.textarea.title")}</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            onChange={(e) => setContent(e.target.value)}
            value={content}
            required
            type="textarea"
            placeholder={t("contact.textarea.placeholder")}
          />
          {message && <Form.Text>{message}</Form.Text>}
        </Form.Group>
        <Button
          onClick={sendMail}
          className={"w-100 h-12 border-none rounded-2xl text-base font-semibold cursor-pointer bg-logo"}
          type="submit"
        >
          {t("contact.send.title")}
        </Button>
      </div>
    </Form>
  );
};

export default Contact;
