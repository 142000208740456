import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Login from "./components/_forms/Login";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./components/_shared/App";
import Contact from "./components/_forms/Contact";
import MyDocuments from "./components/documents/MyDocuments";
import Users from "./components/user/Users";
import ForgotPassword from "./components/_forms/ForgotPassword";
import SendInvitation from "./components/_forms/SendInvitation";
import RenewPassword from "./components/_forms/RenewPassword";
import { Invoice } from "./components/invoice/Invoice";
import i18n from "./118n";
import * as Sentry from "@sentry/react";
import Settings from "./components/settings/Settings";

let env = process.env.REACT_APP_SENTRY_DOMAIN.replace(".", "\\.");
let myRegexp = new RegExp(`^https:\/\/${env}\/api/`);

if (process.env.REACT_APP_ENV === "prod") {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", myRegexp],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, //
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <i18n />
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/renew-password/:token" element={<RenewPassword />} />
      <Route path="/send-invitation" element={<SendInvitation />} />

      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>Theres nothing here!ss</p>
          </main>
        }
      />
      <Route element={<App />}>
        <Route path="/my-documents" element={<MyDocuments />} />
        <Route path="/contact-us" element={<Contact />} />

        {/* ADMIN */}
        <Route path="/settings" element={<Settings />} />
        <Route path="/users" element={<Users />} />

        {/* MAIN ADMIN */}
        <Route path="/invoices" element={<Invoice />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
