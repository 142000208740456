import { useTranslation } from "react-i18next";
import { Link, useOutletContext } from "react-router-dom";
import ModifyUserPassword from "../_forms/ModifyUserPassword";
import { useEffect, useState } from "react";
import ModifyUserMail from "../_forms/ModifyUserMail";
import { companyInformations, displayLogs } from "../../_api/user";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Switch from "react-switch";
import { IsPayrollByMail } from "../../_api/user";
const LoginLogTable = () => {
  const { user } = useOutletContext();

  const { t, i18n } = useTranslation();

  const [logs, setLogs] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("TOKEN");

    displayLogs(token, user.id, i18n.language).then((response) => {
      setLogs(response.data.data);
    });
  });

  return (
    <table className={"border m-3"}>
      <thead>
        <tr className={"center border"}>
          <th className={"w-40"}>{t("log.login.date")}</th>
          <th className={"w-40"}>{t("log.login.hour")}</th>
          <th className={"w-40"}>{t("log.login.ip")}</th>
        </tr>
      </thead>
      <tbody>
        {logs.map((log) => {
          const date = new Date(parseInt(log.created_at * 1000));
          return (
            <tr className={"text-center"}>
              <td className={"w-40"}>{date.toLocaleDateString()}</td>
              <td className={"w-40"}>{date.toLocaleTimeString()}</td>
              <td className={"w-40"}>{log.ip_address}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const CompanyInformation = () => {
  const { t, i18n } = useTranslation();

  const [matricule, setMatricule] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [creationDate, setCreationDate] = useState("");

  const { user } = useOutletContext();

  useEffect(() => {
    const token = localStorage.getItem("TOKEN");

    companyInformations(token, user.id, i18n.language).then((response) => {
      setCompanyName(response.data.data.admin.name);
      setMatricule(response.data.data.user.internal_id);
      setCreationDate(new Date(parseInt(response.data.data.user.created_at) * 1000).toLocaleDateString());
    });
  });
  return (
    <div className={"m-3"}>
      <ul>
        <li>
          {t("company")} : {companyName} - {t("payroll")}
        </li>
        <li>
          {t("activation.date")} : {creationDate}
        </li>
        <li>
          {t("internal.id")} : {matricule}
        </li>
      </ul>
    </div>
  );
};

const PayrollByMailOrNot = () => {
  const { user, setUser } = useOutletContext();

  const { t, i18n } = useTranslation();

  const [receiveByMail, setReceiveByMail] = useState(user.is_using_laposte);
  const [streetNumber, setStreetNumber] = useState(user.number || "");
  const [streetExtension, setStreetExtension] = useState(user.extension || "");
  const [street, setStreet] = useState(user.street_name || "");
  const [zipCode, setZipCode] = useState(user.zip_code || "");
  const [city, setCity] = useState(user.city || "");

  const token = localStorage.getItem("TOKEN");

  useEffect(() => {
    setReceiveByMail(user.is_using_laposte);
  }, []);
  const onChange = (value) => {
    setReceiveByMail(value);
    if (!value) {
      const userModify = user;
      userModify.is_using_laposte = false;
      const payload = {
        id: user.id,
        value: false,
      };

      IsPayrollByMail(token, payload, i18n.language).then((response) => {
        setUser(userModify);
      });
    }
  };

  const updateIsReceivingMail = () => {
    if (zipCode && city && streetNumber && street) {
      const payload = {
        id: user.id,
        value: receiveByMail,
        city,
        zipCode,
        streetNumber,
        street,
        streetExtension,
      };
      const userModify = user;
      userModify.is_using_laposte = receiveByMail;
      IsPayrollByMail(token, payload, i18n.language).then((response) => {
        setUser(userModify);
      });
    }
  };

  return (
    <div>
      <Form.Group className="mb-3 w-72" controlId="formBasicEmail">
        <Switch onChange={(value) => onChange(value)} checked={receiveByMail} />
        {receiveByMail && (
          <Form.Label className={"font-semibold"}>
            {user.zip_code && user.number && user.city
              ? t("user.settings.recept.mail") +
                " " +
                user.number +
                " " +
                user.extension +
                user.street_name +
                ", " +
                user.zip_code +
                " " +
                user.city
              : t("user.settings.fullfil.adress") + "."}
          </Form.Label>
        )}
      </Form.Group>
      {receiveByMail && (
        <>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <div className={"flex"}>
              <Form.Label className={"w-50"}>{t("user.settings.street.number")}*</Form.Label>
              <Form.Label className={"w-50"}>{t("user.settings.street.name")}*</Form.Label>
            </div>
            <div className={"flex"}>
              <Form.Control
                onChange={(e) => setStreetNumber(e.target.value)}
                value={streetNumber}
                required
                type="text"
                placeholder={"5"}
              />
              <Form.Control
                onChange={(e) => setStreet(e.target.value)}
                value={street}
                required
                type="text"
                placeholder={t("user.settings.street.name")}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>{t("user.settings.street.extension")}</Form.Label>
            <Form.Control
              onChange={(e) => setStreetExtension(e.target.value)}
              value={streetExtension}
              required
              type="text"
              placeholder={t("login.email.placeholder")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword"></Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <div className={"flex w-100"}>
              <Form.Label className={"w-50"}>{t("user.settings.city.zipcode")}*</Form.Label>
              <Form.Label className={"w-50"}>{t("user.settings.street.city")}*</Form.Label>
            </div>
            <div className={"flex"}>
              <Form.Control
                onChange={(e) => setZipCode(e.target.value)}
                value={zipCode}
                required
                type="text"
                placeholder={t("login.email.placeholder")}
              />
              <Form.Control
                onChange={(e) => setCity(e.target.value)}
                value={city}
                required
                type="text"
                placeholder={t("login.email.placeholder")}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword"></Form.Group>
          <Button
            onClick={(e) => updateIsReceivingMail(e)}
            className={"w-100 h-12 border-none rounded-2xl text-base font-semibold cursor-pointer bg-logo"}
            type="submit"
          >
            {t("user.settings.recept.by.mail")}
          </Button>
        </>
      )}
    </div>
  );
};

const UserSettings = () => {
  const { t } = useTranslation();

  const [modifyPassword, setModifyingPassword] = useState(false);
  const [modifyEmail, setModifyingEmail] = useState(false);
  const [displayLogs, setDisplayLogs] = useState(false);
  const [usingDigital, setUsingDigital] = useState(false);
  const [showCompanyInfos, setShowCompanyInfos] = useState(false);

  return (
    <div>
      <Link to={"../my-documents"} className={"mb-2"}>
        <Button className={"mb-2 h-12 border-none rounded-2xl text-base font-semibold cursor-pointer bg-logo"}>
          {"< " + t("my.account.see.documents")}
        </Button>
      </Link>

      <ul>
        <li
          className={"text-blue-600 cursor-pointer"}
          onClick={() => {
            setModifyingPassword(!modifyPassword);
            setModifyingEmail(false);
            setDisplayLogs(false);
            setUsingDigital(false);
            setShowCompanyInfos(false);
          }}
        >
          {t("my.account.modify.password")}
        </li>
        {modifyPassword && (
          <li>
            <ModifyUserPassword />
          </li>
        )}
        <li
          className={"text-blue-600 cursor-pointer"}
          onClick={() => {
            setModifyingPassword(false);
            setModifyingEmail(!modifyPassword);
            setDisplayLogs(false);
            setUsingDigital(false);
            setShowCompanyInfos(false);
          }}
        >
          {t("my.account.modify.email")}
        </li>
        {modifyEmail && (
          <li>
            <ModifyUserMail />
          </li>
        )}
        {/*<li className={"text-blue-600 cursor-pointer"}>{t("my.account.modify.alert")}</li>*/}
        <li
          className={"text-blue-600 cursor-pointer"}
          onClick={() => {
            setModifyingPassword(false);
            setModifyingEmail(false);
            setDisplayLogs(!displayLogs);
            setUsingDigital(false);
            setShowCompanyInfos(false);
          }}
        >
          {t("my.account.display.logs")}
        </li>
        {displayLogs && (
          <li>
            <LoginLogTable />
          </li>
        )}

        {/*<li className={"text-blue-600 cursor-pointer"}>{t("my.account.select.contact")}</li>*/}
        <li
          className={"text-blue-600 cursor-pointer"}
          onClick={() => {
            setModifyingPassword(false);
            setModifyingEmail(false);
            setDisplayLogs(false);
            setUsingDigital(!usingDigital);
            setShowCompanyInfos(false);
          }}
        >
          {t("my.account.select.no_digital")}
        </li>
        {usingDigital && (
          <li>
            <PayrollByMailOrNot />
          </li>
        )}
        <li
          className={"text-blue-600 cursor-pointer"}
          onClick={() => {
            setModifyingPassword(false);
            setModifyingEmail(false);
            setDisplayLogs(false);
            setUsingDigital(false);
            setShowCompanyInfos(!showCompanyInfos);
          }}
        >
          {t("my.account.infos.company")}
        </li>
        {showCompanyInfos && (
          <li>
            <CompanyInformation />
          </li>
        )}
      </ul>
    </div>
  );
};

export default UserSettings;
