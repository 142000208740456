import Form from "react-bootstrap/Form";
import DefaultDropdown from "./input/Dropdown";

const DefaultLanguage = (props) => {
  const { options, language, setLanguage, name, disabled } = props;
  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label className={"font-semibold"}>{name}</Form.Label>
      <DefaultDropdown disabled={disabled} options={options} value={language} onChange={setLanguage} />
    </Form.Group>
  );
};

export default DefaultLanguage;
