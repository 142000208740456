import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { updatePassword } from "../../_api/user";
import { useOutletContext } from "react-router-dom";

const ModifyUserPassword = () => {
  const { t, i18n } = useTranslation();

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [error, setError] = useState("");

  const { user } = useOutletContext();

  const isCorrectPassword = () => {
    return password1.length > 3;
  };

  const isPasswordMatching = () => {
    return password1 === password2;
  };

  const askRenewPassword = (e) => {
    e.preventDefault();

    const payload = {
      password: password1,
      id: user.id,
    };
    if (!isPasswordMatching()) {
      setError(t("renew.password_unmatch"));
      return;
    }

    if (!isCorrectPassword()) {
      setError(t("renew.password.security"));
      return;
    }

    const token = localStorage.getItem("TOKEN");
    updatePassword(payload, token, i18n.language)
      .then((response) => {
        setError(t("renew.password.success"));
        setPassword1("");
        setPassword2("");
      })
      .catch((error) => {
        console.log(error);
        setError(t("renew.password.failed"));
      });
  };

  return (
    <div className="flex">
      <Form>
        <div className={"p-3"}>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>{t("login.password.name")}</Form.Label>
            <Form.Control
              onChange={(e) => setPassword1(e.target.value)}
              value={password1}
              required
              type="password"
              placeholder={t("login.password.placeholder")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword2">
            <Form.Label>{t("renew.password.verify")}</Form.Label>
            <Form.Control
              onChange={(e) => setPassword2(e.target.value)}
              value={password2}
              required
              type="password"
              placeholder={t("login.password.placeholder")}
            />
            {error && <Form.Text className="text-muted">{error}</Form.Text>}
          </Form.Group>
          <Button
            onClick={(e) => askRenewPassword(e)}
            className={"w-100 h-12 border-none rounded-2xl text-base font-semibold cursor-pointer bg-logo"}
            type="submit"
          >
            {t("renew.password.submit")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ModifyUserPassword;
