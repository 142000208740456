import axios from "axios";
import { BACK_END_URL, privateHeader, onError, privateHeaderUpload } from "./_shared";

export const createUser = (token, payload, language) => {
  return axios
    .post(BACK_END_URL + "/user/create", payload, {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};

export const updateUser = (token, payload, language, _id) => {
  return axios
    .post(BACK_END_URL + "/user/update/" + _id, payload, {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};

export const deleteUser = (token, payload, language, _id) => {
  return axios
    .post(BACK_END_URL + "/user/delete/" + _id, payload, {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};

export const sendInvitation = (payload, language) => {
  return axios.post(BACK_END_URL + "/user/send-invitation", payload, {
    headers: { "Accept-Language": language },
  });
};

export const askRenewPassword = (payload, language) => {
  return axios.post(BACK_END_URL + "/user/ask_renew_password", payload, {
    headers: { "Accept-Language": language },
  });
};

export const renewPassword = (payload, token, language) => {
  return axios.post(BACK_END_URL + "/user/renew_password/" + token, payload, {
    headers: { "Accept-Language": language },
  });
};

export const updatePassword = (payload, token, language) => {
  return axios
    .post(BACK_END_URL + "/user/update/password", payload, {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};

export const updateMail = (payload, token, language) => {
  return axios
    .post(BACK_END_URL + "/user/update/email", payload, {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};

export const displayLogs = (token, id, language) => {
  return axios
    .get(BACK_END_URL + "/user/login_logs/" + id, {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};

export const companyInformations = (token, id, language) => {
  return axios
    .get(BACK_END_URL + "/user/company_information/" + id, {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};

export const IsPayrollByMail = (token, payload, language) => {
  return axios
    .post(BACK_END_URL + "/user/send_payroll_by_mail", payload, {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};

export const getStatsRH = (token, id, language) => {
  return axios
    .get(BACK_END_URL + "/user/documents_stats/" + id, {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};

export const getUsersPaySlipStatus = async (ids, language) => {
  const token = localStorage.getItem("TOKEN");

  return await axios
    .post(
      BACK_END_URL + "/user/payslips",
      { ids: ids },
      {
        headers: privateHeader(token, language),
      }
    )
    .catch(onError);
};

export const UpdateUsersPaySlipStatus = (payload, language) => {
  const params = "?year=" + payload["year"] + "&month=" + payload["month"];
  const token = localStorage.getItem("TOKEN");
  const formData = new FormData();
  formData.append("month", payload.month);
  formData.append("year", payload.year);
  formData.append("admin_id", payload.admin_id);
  formData.append("file", payload?.file, payload?.file.name);
  return axios
    .post(BACK_END_URL + "/user/payslips/status" + params, formData, {
      headers: privateHeaderUpload(token, language),
    })
    .catch(onError);
};
