import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../_assets/Capture.PNG";
import DefaultDropdown from "../input/Dropdown";

const UserMenu = (props) => {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(props.props.user.default_language);
  const languageMapper = {
    fr: "Français",
    en: "English",
    es: "Español",
  };

  const languages = props.props.user.languages.map((language) => {
    return { value: language, label: languageMapper[language] };
  });
  const changeLanguage = (value) => {
    i18n.changeLanguage(value.value);
    setLanguage(value.value);
  };

  return (
    <div className={"flex items-center justify-center bg-slate-50 h-12"}>
      <div className={"w-2/3"}>
        <Link to={"/my-documents"} className={"absolute top-0"}>
          <img src={Logo} height={125} width={325} alt={"Logo"} />
        </Link>
        <ul className={"flex justify-end"}>
          <Link to={"/contact-us"}>
            <li className={"w-32 h-12 text-center font-semibold leading-12"}>Contact</li>
          </Link>
          <li className={"p-1 w-32 h-12 text-center font-semibold"}>
            <DefaultDropdown
              options={languages}
              value={languageMapper[language]}
              onChange={(value) => changeLanguage(value)}
            />
          </li>
          <Link to={"/settings"}>
            <li className={"w-32 h-12 text-center font-semibold leading-12"}>{t("app.menu.account")}</li>
          </Link>
          <Link
            to={"/login"}
            onClick={() => {
              props.setUser(null);
              localStorage.setItem("TOKEN", null);
            }}
          >
            <li className={"w-32 h-12 text-center font-semibold leading-12"}>{t("app.menu.unconnect")}</li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default UserMenu;
