import { useState } from "react";
import Form from "react-bootstrap/Form";
import Switch from "react-switch";
import DefaultLanguage from "../_shared/DefaultLanguage";
import Selector from "../_shared/input/MultiSelector";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const languageOptions = ["FR"];

const ManagerSettings = (props) => {
  const { user } = props;
  const { t } = useTranslation();

  const optionsMapping = { fr: t("language.french"), en: t("language.english") };

  const options = user.languages.map((language) => {
    return { value: language, label: optionsMapping[language] };
  });

  const [defaultLanguage, setDefaultLanguage] = useState(user.default_language.toUpperCase());

  const [deleteRight, setDeleteRight] = useState(user.rights.destroy_file);
  const [renameRight, setRenameRight] = useState(user.rights.rename_file);
  const [importRight, setImportRight] = useState(user.rights.import_file);
  const [exportRight, setExportRight] = useState(user.rights.export_file);
  const [createFolderRight, setCreateFolderRight] = useState(user.rights.create_folder);

  return (
    <>
      <ul>
        <h1 className={"font-semibold text-xl mt-2 mb-2"}>{t("settings.users.rights")}</h1>
        <hr />
        <div className={"flex"}>
          <Form.Group className="mb-3 w-72" controlId="formBasicEmail">
            <Form.Label className={"font-semibold"}>{t("settings.users.right.rename")}</Form.Label>
            <br />
            <Switch disabled={true} onChange={(e) => setRenameRight(!renameRight)} checked={renameRight} />
          </Form.Group>
          <Form.Group className="mb-3 w-72" controlId="formBasicEmail">
            <Form.Label className={"font-semibold"}>{t("settings.users.right.delete")}</Form.Label>
            <br />
            <Switch disabled={true} onChange={(e) => setDeleteRight(!deleteRight)} checked={deleteRight} />
          </Form.Group>
          <Form.Group className="mb-3 w-72" controlId="formBasicEmail">
            <Form.Label className={"font-semibold"}>{t("settings.users.right.create")}</Form.Label>
            <br />
            <Switch
              disabled={true}
              onChange={(e) => setCreateFolderRight(!createFolderRight)}
              checked={createFolderRight}
            />
          </Form.Group>
        </div>
        <div className={"flex"}>
          <Form.Group className="mb-3 w-72" controlId="formBasicEmail">
            <Form.Label className={"font-semibold"}>{t("import")}</Form.Label>
            <br />

            <Switch disabled={true} onChange={(e) => setImportRight(!importRight)} checked={importRight} />
          </Form.Group>
          <Form.Group className="mb-3 w-72" controlId="formBasicEmail">
            <Form.Label className={"font-semibold"}>{t("export")}</Form.Label>
            <br />
            <Switch disabled={true} onChange={(e) => setExportRight(!exportRight)} checked={exportRight} />
          </Form.Group>
        </div>
        <h1 className={"font-semibold text-xl mt-2 mb-2"}>{t("settings.users.languages.title")}</h1>
        <hr />
        <li>
          <DefaultLanguage
            disabled={true}
            name={t("settings.users.language.default")}
            options={languageOptions}
            language={defaultLanguage}
            setLanguage={setDefaultLanguage}
          />
        </li>
        <li>
          <Selector isMulti={true} disabled={true} options={options} name={t("settings.users.language.autorized")} />
        </li>
      </ul>
      <Alert variant={"warning"}>{t("warning.rights.update")}</Alert>
    </>
  );
};

export default ManagerSettings;
