import { firstLetterCapitalize } from "../../../_helpers/format";
import DistribMenu from "./DistribMenu";
import ManagerMenu from "./ManagerMenu";
import UserMenu from "./UserMenu";
import MainAdminMenu from "./MainAdminMenu";

const Menu = (props) => {
  const { user } = props;
  const userType = user?.user_type;
  const userInfos =
    userType === "USER" ? (
      <>
        - {firstLetterCapitalize(user?.first_name)}.{firstLetterCapitalize(user?.last_name)}
      </>
    ) : (
      ""
    );
  return (
    <>
      {user && userType === "USER" && <UserMenu props={props} />}
      {user && userType === "ADMIN" && <MainAdminMenu user={user} />}
      {user && userType === "DISTRIB" && <DistribMenu props={props} />}
      {user && userType === "MANAGER" && <ManagerMenu props={props} />}
      {user && (
        <div className={"flex items-center justify-center"}>
          <div className={"m-2 w-2/3 flex justify-end"}>
            {user.email} {userInfos}
          </div>
        </div>
      )}
    </>
  );
};

export default Menu;
