import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateMail } from "../../_api/user";
import { useOutletContext } from "react-router-dom";

const ModifyUserMail = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();

  const { user, setUser } = useOutletContext();

  useEffect(() => {
    setEmail(user.email);
  }, [user]);
  const renewMail = (e) => {
    e.preventDefault();

    const payload = {
      email: email,
      id: user.id,
    };

    const userModify = user;
    userModify.email = email;

    const token = localStorage.getItem("TOKEN");
    updateMail(payload, token, i18n.language)
      .then((response) => {
        setError(t("renew.mail.success"));
        setEmail("");
        setUser(userModify);
      })
      .catch((error) => {
        console.log(error);
        setError(t("renew.password.failed"));
      });
  };
  return (
    <div className="flex">
      <Form>
        <div className={"p-4"}>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Mail</Form.Label>
            <Form.Control
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              type="text"
              placeholder={t("login.email.placeholder")}
            />
            {error && <Form.Text className="text-muted">{error}</Form.Text>}
          </Form.Group>
          <Button
            onClick={(e) => renewMail(e)}
            className={"w-100 h-12 border-none rounded-2xl text-base font-semibold cursor-pointer bg-logo"}
            type="submit"
          >
            {t("renew.mail.submit")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ModifyUserMail;
