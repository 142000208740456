import Form from "react-bootstrap/Form";
import Logo from "../../_assets/Capture.PNG";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { sendInvitation } from "../../_api/user";
import { useTranslation } from "react-i18next";

const SendInvitation = () => {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState("");

  const [error, setError] = useState("");

  const isMail = () => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  const submitInvitation = async (e) => {
    e.preventDefault();
    setError("");

    if (isMail()) {
      const payload = { email };

      sendInvitation(payload, i18n.language)
        .then((response) => {
          setError(response.data.message);
          setEmail("");
        })
        .catch((error) => {
          setError(error.response.data.message);
        });
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-slate-50">
      <Form className={"rounded border border-indigo-600 bg-white"}>
        <div>
          <Link to={"/login"}>
            <img src={Logo} height={200} width={400} alt={"Logo"} />
          </Link>
        </div>
        <hr />

        <div className={"p-4"}>
          <div className={"m-3"}>
            <p className={"text-muted max-w-xs text-justify"}>{t("invitation.infos")}</p>
          </div>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              type="email"
              placeholder={t("login.email.placeholder")}
            />
            <Form.Text className="text-muted">{error}</Form.Text>
          </Form.Group>
          <Button
            onClick={(e) => submitInvitation(e)}
            className={"w-100 h-12 border-none rounded-2xl text-base font-semibold cursor-pointer bg-logo"}
            type="submit"
          >
            {t("invitation.send")}
          </Button>
          <Link to={"/login"}>
            <Button
              className={"w-100 mt-2 border-none h-12 rounded-2xl text-base font-semibold cursor-pointer bg-logo"}
              type="submit"
            >
              {t("back")}
            </Button>
          </Link>
        </div>
      </Form>
    </div>
  );
};

export default SendInvitation;
