import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDocumentsByUser } from "../../_api/document";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const DepositHistory = (props) => {
  const { t, i18n } = useTranslation();
  const { user } = props;
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("TOKEN");

    getDocumentsByUser(token, i18n.language, user.id).then((resp) => {
      setDocuments(resp.data.data);
    });
  }, [props.tab, user]);

  return (
    <div className={"max-h-full"}>
      <div className={"h-100 w-100 border-x-2"}>
        <table className={"w-100 mb-4 border text-sm"} id={"documents"}>
          <thead>
            <tr>
              <th className={"max-h-2 pl-2 pb-2 border h-7.5"}>Nom de fichier</th>
              <th className={"max-h-2 pl-2 pb-2 border h-7.5"}>Date de dépot</th>
              <th className={"max-h-2 pl-2 pb-2 border h-7.5"}>Dépositaire</th>
              <th className={"max-h-2 pl-2 pb-2 border h-7.5"}>Lu</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((document) => {
              return (
                <tr className={"cursor-pointer h-8"}>
                  <td className={`pl-2 h-8 whitespace-nowrap max-w-xs text-ellipsis overflow-hidden`}>
                    {document.name}
                  </td>
                  <td className={`pl-2 h-8`}>
                    {new Date(document.created_at * 1000).toLocaleDateString()}{" "}
                    {new Date(document.created_at * 1000).toLocaleTimeString()}
                  </td>
                  <td className={`pl-2 h-8`}>{document.uploader_user}</td>
                  <td className={`pl-2 h-8`}>{document.is_download ? <CheckOutlined /> : <CloseOutlined />}</td>
                </tr>
              );
            })}
            {documents.length === 0 && (
              <tr>
                <td colSpan={4} className={"text-center"}>
                  {t("document.table.no.data")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DepositHistory;
