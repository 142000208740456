import axios from "axios";

export const BACK_END_URL = process.env.REACT_APP_API_URL;

export const onError = (error) => {
  if (error?.response?.status === 401) {
    window.location.href = "/login";
  }
  throw error;
};

export const privateHeader = (token, language = "") => {
  return {
    Authorization: token,
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": language,
  };
};
export const privateFileHeader = (token, language) => {
  return {
    Authorization: token,
    "Access-Control-Allow-Origin": "*",
    responseType: "blob",
  };
};
export const privateHeaderUpload = (token, language) => {
  return {
    "content-type": "multipart/form-data",
    Authorization: token,
    "Accept-Language": language,
  };
};

const client = axios.create({ baseURL: BACK_END_URL });

export const request = ({ ...options }) => {
  const onSuccess = (response) => response;

  return client(options).then(onSuccess).catch(onError);
};
