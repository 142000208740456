import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Logo from "../../../_assets/Capture.PNG";

const MainAdminMenu = (props) => {
  const { user } = props;
  const { t } = useTranslation();

  return (
    <div className={"flex items-center justify-center bg-slate-50 h-12"}>
      <div className={"w-2/3"}>
        <Link to={"/users"} className={"absolute top-0"}>
          <img src={Logo} height={125} width={325} alt={"Logo"} />
        </Link>
        <ul className={"flex justify-end"}>
          <Link to={"/contact-us"}>
            <li className={"w-32 h-12 text-center font-semibold leading-12"}>Contact</li>
          </Link>
          <Link to={"/users"}>
            <li className={"w-32 h-12 text-center font-semibold leading-12"}>
              {t("app.menu.admin.documents.managers")}
            </li>
          </Link>
          <Link to={"/my-documents"}>
            <li className={"w-32 h-12 text-center font-semibold leading-12"}>{t("app.menu.admin.documents.safe")}</li>
          </Link>
          {user && !user.distributor_id && (
            <Link to={"/invoices"}>
              <li className={"w-32 h-12 text-center font-semibold leading-12"}>{t("app.menu.admin.invoices")}</li>
            </Link>
          )}
          <Link
            to={"/login"}
            onClick={() => {
              props.setUser(null);
              localStorage.setItem("TOKEN", null);
            }}
          >
            <li className={"w-32 h-12 text-center font-semibold leading-12"}>{t("app.menu.unconnect")}</li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default MainAdminMenu;
