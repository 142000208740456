import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../_shared/loader/loader";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { renameDocument } from "../../../_api/document";

export const RenameFileModal = (props) => {
  const { t, i18n } = useTranslation();

  const { show, selectedRow, getDoc, onHide } = props;
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    if (fileName !== "") {
      setIsLoading(true);

      const payload = {
        name: fileName,
        key: selectedRow.key,
      };

      renameDocument(payload, i18n.language, selectedRow.id).then((resp) => {
        let split = selectedRow.key.split("/");
        split = split.filter((e) => e !== "");
        getDoc(split.slice(0, split.length - 1).join("/") + "/");
        setIsLoading(false);
        setFileName("");
        onHide();
      });
    }
  };

  useEffect(() => {}, []);
  return (
    <>
      <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t("document.rename.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>{t("document.rename")}</Form.Label>
              <Form.Control
                onChange={(e) => setFileName(e.target.value)}
                value={fileName}
                required
                type="text"
                placeholder={t("document.rename")}
              />
            </Form.Group>
            {isLoading && <LoadingSpinner />}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            className={"mb-2 h-10 rounded-2xl border-none text-base font-semibold cursor-pointer bg-logo"}
            onClick={() => validateForm()}
          >
            {t("document.rename.action")}
          </Button>
          <Button
            disabled={isLoading}
            className={"mb-2 h-10 rounded-2xl border-none text-base font-semibold cursor-pointer bg-logo"}
            onClick={() => onHide()}
          >
            {t("back")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
