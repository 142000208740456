import { useTranslation } from "react-i18next";

export const TableFooter = (props) => {
  const { user } = props;
  const { t } = useTranslation();

  return (
    <p className={"italic text-xs"}>
      {t("document.use.memory")} {user.space.toFixed(2)} Mo ({(user.space / user.space_limit).toFixed(1)}%) {t("on")}{" "}
      {user.space_limit / 10} Go {t("available")}.
    </p>
  );
};
