import { useTranslation } from "react-i18next";

export const TableHead = (props) => {
  const { t, i18n } = useTranslation();
  const { onClick } = props;
  return (
    <th colSpan="1" className={"max-h-2 pl-2 pb-2 border h-7.5 cursor-pointer"} onClick={onClick}>
      {t("document.table.title." + props.name)}
    </th>
  );
};

export default TableHead;
