import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { getInvoices } from "../../_api/admin";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../_shared/loader/loader";

export const Invoice = () => {
  const [invoices, setInvoices] = useState([]);
  const [isLoadinInvoice, setIsLoadingInvoices] = useState(false);
  const [infos, setInfos] = useState(null);

  const { user } = useOutletContext();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setIsLoadingInvoices(true);

    const token = localStorage.getItem("TOKEN");

    getInvoices(token, i18n.language).then((response) => {
      setInvoices(response.data.data.invoices.data);
      setInfos(response.data.data.infos);
      setIsLoadingInvoices(false);
    });
  }, [user, i18n.language]);

  function round(num, decimalPlaces = 0) {
    if (num < 0) return -round(-num, decimalPlaces);
    var p = Math.pow(10, decimalPlaces);
    var n = num * p;
    var f = n - Math.floor(n);
    var e = Number.EPSILON * n;
    return f >= 0.5 - e ? Math.ceil(n) / p : Math.floor(n) / p;
  }

  return (
    <div>
      <div className={"flex justify-between"}>
        <div className="w-52 rounded overflow-hidden shadow-lg">
          <div className="px-6 py-4 h-28">
            <div className="font-bold text-xl mb-2">{t("invoices.pricing")}</div>
            {infos && <p className="text-gray-700 text-base">€{Math.round((infos._sum / 100) * 1.2 * 100) / 100}</p>}
          </div>
        </div>
        <div className="w-52 rounded overflow-hidden shadow-lg">
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">{t("invoices.users")}</div>
            {infos && (
              <p className="text-gray-700 text-base">
                {infos.users_count} {t("invoices.employees")}
              </p>
            )}
          </div>
        </div>
        <div className="w-52 rounded overflow-hidden shadow-lg">
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">{t("invoices.limit.stockage")}</div>
            {infos && <p className="text-gray-700 text-base">{infos.space} Go</p>}
          </div>
        </div>
        <div className="w-52 rounded overflow-hidden shadow-lg">
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">{t("invoices.pay.month")}</div>
            {infos && <p className="text-gray-700 text-base">{infos.active_user}</p>}
          </div>
        </div>
      </div>
      {isLoadinInvoice && <LoadingSpinner />}

      {!isLoadinInvoice && (
        <table className={"w-100 mt-4"}>
          <thead>
            <tr>
              <th colSpan={3} className={"text-lg"}>
                {t("invoices")}
              </th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => {
              let currency = invoice.currency === "eur" ? "€" : "$";
              let date = new Date(invoice.period_end * 1000).toDateString().split(" ");
              return (
                <tr key={invoice.id} className="border-y">
                  <td
                    className="text-sky-400 cursor-pointer"
                    onClick={() => window.open(invoice?.hosted_invoice_url, "_blank")}
                  >
                    {date[1]} {date[3]}
                  </td>
                  <td>
                    {currency}
                    {round(invoice.amount_paid / 100, 2)}
                  </td>
                  <td>Paid</td>
                </tr>
              );
            })}
            {invoices.length === 0 && (
              <tr key={"empty"} className="border-y">
                <td colSpan={3}>{t("invoices.empty")}</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};
