import { DeleteOutlined, FolderOpenOutlined, FolderOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const LeftSideMenuTable = (props) => {
  const { t } = useTranslation();
  const { getDoc, parentFolder, setCurrentFolder } = props;

  useEffect(() => {}, [parentFolder]);

  return (
    <div className={"w-2/6 border"}>
      <ul className={"p-2"}>
        <li
          className={"leading-8 cursor-pointer hover:bg-slate-200 "}
          onClick={() => {
            getDoc(parentFolder);
            setCurrentFolder({ key: parentFolder });
          }}
        >
          <FolderOutlined className={"p-1"} />
          {t("document.category.title")}
        </li>
        <ul className={"pl-4"}>
          <li
            className={"leading-8 cursor-pointer hover:bg-slate-200"}
            onClick={() => {
              getDoc(parentFolder);
              setCurrentFolder({ key: parentFolder });
            }}
          >
            <FolderOpenOutlined className={"p-1"} />
            {t("document.subcategory.receive")}
          </li>
          <li className={"leading-8 cursor-not-allowed hover:bg-slate-200"}>
            <DeleteOutlined className={"p-1"} />
            {t("document.subcategory.trash")}
          </li>
        </ul>
      </ul>
    </div>
  );
};
