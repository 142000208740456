import { Outlet, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import Menu from "./menu/Menu";
import { getInfos } from "../../_api/auth";
import { getPullingStatus } from "../../_api/document";
import { CloseOutlined, DownOutlined, UpOutlined, CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { imageMapper } from "../../_helpers/constants";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "./loader/loader";

const DocumentStatus = (status) => {
  if (status === "PENDING") {
    return <LoadingSpinner style={{ width: 20, height: 20 }} />;
  }
  if (status === "SUCCESS") {
    return <CheckCircleFilled twoToneColor="#52c41a" />;
  }
  if (status === "FAILURE") {
    return <CloseCircleFilled />;
  }
};
const AuthorizedRoutesByProfile = {
  "/my-documents": {
    MANAGER: true,
    USER: true,
    ADMIN: true,
    DISTRIBUTOR: false,
  },
  "/contact-us": {
    MANAGER: true,
    USER: true,
    ADMIN: true,
    DISTRIBUTOR: true,
  },
  "/settings": {
    MANAGER: [],
    USER: [],
    ADMIN: [],
    DISTRIBUTOR: [],
  },
  "/users": {
    MANAGER: true,
    USER: false,
    ADMIN: true,
    DISTRIBUTOR: true,
  },
  "/invoices": {
    MANAGER: false,
    USER: false,
    ADMIN: true,
    DISTRIBUTOR: false,
  },
};

const App = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState();
  const [uploadList, setUploadList] = useState({ uploadList: [], data: null });
  const [isUploadVisible, setIsUploadVisible] = useState(false);
  const [isUploadDown, setIsUploadDown] = useState(false);
  const [isPollingEnabled, setIsPollingEnabled] = useState(false);
  const [pollingID, setPollingId] = useState(null);
  const timerIdRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  async function loadData() {
    const token = localStorage.getItem("TOKEN");
    await getInfos(token)
      .then((user) => {
        setUser(user.data.data);
      })
      .catch((error) => {
        navigate("/login");
      });
  }

  useEffect(() => {
    if (!user) {
      loadData();
    }
  }, [user]);

  useEffect(() => {
    const pollingCallback = () => {
      getPullingStatus({ uploadList: uploadList.uploadList })
        .then((resp) => {
          setUploadList(resp.data);
          const keepPulling = resp.data.uploadList.map((file) => file.task_status === "SUCCESS");
          if (keepPulling.every((v) => v === true)) {
            setIsPollingEnabled(false);
            setPollingId(null);
          }
        })
        .catch((resp) => {
          console.log(resp);
          setPollingId(null);
          setIsPollingEnabled(false);
        });
    };

    const startPolling = () => {
      pollingCallback();
      timerIdRef.current = setInterval(pollingCallback, 5000);
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current);
    };

    if (isPollingEnabled && pollingID) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [isPollingEnabled, pollingID]);

  useEffect(() => {
    if (user) {
      let route = location.pathname;
      if (!AuthorizedRoutesByProfile[route][user.user_type]) {
        navigate("/login");
      }
    }
  }, [user, location, navigate]);

  return (
    <div className="app">
      <div className="h-screen">
        {isUploadVisible && (
          <div className="min-w-80 w-80 absolute bottom-0 right-10 border-2 rounded-t-xl">
            <div className="h-10 bg-slate-50 flex justify-between">
              <div>
                <h3 className="h-10 leading-10 font-semibold pl-2">Liste des téléchargements</h3>
              </div>
              <div className="flex">
                <div className="pr-2 leading-8 text-lg cursor-pointer" onClick={() => setIsUploadDown(!isUploadDown)}>
                  {isUploadDown ? <UpOutlined /> : <DownOutlined />}
                </div>
                <div
                  className={"pr-2 leading-8 text-lg cursor-pointer"}
                  onClick={() => {
                    setIsUploadVisible(!isUploadVisible);
                    setUploadList({ uploadList: [], data: null });
                  }}
                >
                  <CloseOutlined />
                </div>
              </div>
            </div>
            {!isUploadDown && (
              <div className={""}>
                {/* <div className="flex justify-between pl-2 pr-2 pt-1 pb-1 bg-appColor  bg-opacity-90">
                  <div className={"italic text-sm"}>1 min left</div>
                  <div className={"italic cursor-pointer underline text-sm"}>Cancel</div>
                </div>
                <hr /> */}
                <div className={"max-h-40 overflow-y-auto"}>
                  {uploadList.uploadList.map((document) => {
                    return (
                      <div className={"p-2 flex justify-between w-100 bg-white border-b"}>
                        <div className={"flex overflow-ellipsis overflow-hidden w-80"}>
                          <img
                            src={imageMapper[document.mimetype]}
                            height={20}
                            width={20}
                            alt={t("document.table.alt.document.type")}
                          />
                          <div className={"overflow-ellipsis pl-2 pr-2"}>{document.name}</div>
                        </div>
                        <div className={"w-10 text-center"}>{DocumentStatus(document?.status || "PENDING")}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}

        <Menu user={user} setUser={setUser} />
        {user ? (
          <div className={"mt-5 flex justify-center background-height"}>
            <div className={"w-2/3 bg-slate-50 p-8 pt-3"}>
              {user && (
                <Outlet
                  context={{
                    user: user,
                    setUser: setUser,
                    uploadList,
                    setIsPollingEnabled,
                    setPollingId,
                    setUploadList,
                    setIsUploadVisible,
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default App;
