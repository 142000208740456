import axios from "axios";
import { BACK_END_URL, privateHeader, onError } from "./_shared";

export const getUsers = (token, language) => {
  return axios
    .get(BACK_END_URL + "/admin/users", {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};

export const getInvoices = (token, language) => {
  return axios
    .get(BACK_END_URL + "/admin/invoices", {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};
