import PDF from "../_assets/pdf.png";
import PNG from "../_assets/png.png";
import JPEG from "../_assets/jpeg.png";

export const imageMapper = {
  "application/pdf": PDF,
  "image/png": PNG,
  "image/jpeg": JPEG,
  "image/svg+xml": PDF,
  "application/vnd.ms-excel": PDF,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": PDF,
  "application/x-7z-compressed": PDF,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": PDF,
  "application/vnd.ms-powerpoint": PDF,
  "video/mpeg": PDF,
  "application/msword": PDF,
};

export const fileTypes = ["JPG", "PNG", "GIF", "PDF", "XLS", "XLSX"];

const years = [];
var max = new Date().getFullYear();
var min = max - 9;

for (var i = max; i >= min; i--) {
  years.push({ value: i, label: i });
}

export const options = years;
