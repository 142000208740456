import Logo from "../../_assets/Capture.PNG";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import { Link, useParams } from "react-router-dom";
import { renewPassword } from "../../_api/user";
import { useTranslation } from "react-i18next";

const RenewPassword = () => {
  const { t, i18n } = useTranslation();

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [error, setError] = useState("");

  let { token } = useParams();
  const isCorrectPassword = () => {
    return password1.length > 3;
  };

  const isPasswordMatching = () => {
    return password1 === password2;
  };

  const askRenewPassword = (e) => {
    e.preventDefault();
    const payload = {
      password1,
      password2,
    };
    if (!isPasswordMatching()) {
      setError(t("renew.password_unmatch"));
      return;
    }

    if (!isCorrectPassword()) {
      setError(t("renew.password.security"));
      return;
    }
    renewPassword(payload, token, i18n.language)
      .then((response) => {
        setError(t("renew.password.success"));
        setPassword1("");
        setPassword2("");
      })
      .catch((error) => {
        console.log(error);
        setError(t("renew.password.failed"));
      });
  };

  return (
    <div className="flex items-center justify-center h-screen bg-slate-50">
      <Form className={"rounded border border-indigo-600 bg-white"}>
        <div>
          <Link to={"/login"}>
            <img src={Logo} height={200} width={400} alt={"Logo"} />
          </Link>
        </div>
        <hr />
        <div className={"p-4"}>
          <div className={"m-3"}>
            <p className={"text-muted max-w-xs text-justify"}>{t("renew.password.infos")}</p>
          </div>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>{t("login.password.name")}</Form.Label>
            <Form.Control
              onChange={(e) => setPassword1(e.target.value)}
              value={password1}
              required
              type="password"
              placeholder={t("login.password.placeholder")}
            />
            {error && <Form.Text className="text-muted">{error}</Form.Text>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword2">
            <Form.Label>{t("renew.password.verify")}</Form.Label>
            <Form.Control
              onChange={(e) => setPassword2(e.target.value)}
              value={password2}
              required
              type="password"
              placeholder={t("login.password.placeholder")}
            />
            {error && <Form.Text className="text-muted">{error}</Form.Text>}
          </Form.Group>
          <Button
            onClick={(e) => askRenewPassword(e)}
            className={"w-100 h-12 border-none rounded-2xl text-base font-semibold cursor-pointer bg-logo"}
            type="submit"
          >
            {t("renew.password.submit")}
          </Button>
          <Link to={"/login"}>
            <Button
              className={"w-100 border-none mt-2 h-12 rounded-2xl text-base font-semibold cursor-pointer bg-logo"}
              type="submit"
            >
              {t("back")}
            </Button>
          </Link>
        </div>
      </Form>
    </div>
  );
};

export default RenewPassword;
