import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { login } from "../../_api/auth";
import { Link, Navigate } from "react-router-dom";
import Logo from "../../_assets/Capture.PNG";
import { useTranslation } from "react-i18next";

function Login() {
  const { t, i18n } = useTranslation();

  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");

  const isMail = () => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  const isCorrectPassword = () => {
    return password.length > 3;
  };

  const connect = async (e) => {
    setError("");
    e.preventDefault();
    if (!isCorrectPassword()) {
      setError(t("login.password.small"));
    } else {
      if (isMail()) {
        await login({ email: email, password: password }, i18n.language)
          .then((response) => {
            localStorage.setItem("TOKEN", response.data.user.token);
            setUser(response.data.user);
          })
          .catch((error) => {
            setError(error.response.data.message);
          });
      } else {
        setError(t("login.id.incorrect"));
      }
    }
  };

  return (
    <>
      {user && <Navigate to={user.TYPE === "USER" ? "/my-documents" : "/users"} state={user} replace={true} />}
      <div className="flex items-center justify-center h-screen bg-slate-50">
        <Form className={"rounded border border-indigo-600 bg-white"}>
          <div>
            <img src={Logo} height={175} width={325} alt={"Logo"} />
          </div>
          <hr />
          <div className={"p-4"}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                type="email"
                placeholder={t("login.email.placeholder")}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>{t("login.password.name")}</Form.Label>
              <Form.Control
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                type="password"
                placeholder={t("login.password.placeholder")}
              />
              {error && <Form.Text className="text-muted">{error}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Text className="text-muted">
                <Link to="/forgot-password">{t("login.password.forgot")}</Link>
              </Form.Text>
            </Form.Group>

            <Button
              onClick={connect}
              className={"w-100 h-12 border-none rounded-2xl text-base font-semibold cursor-pointer bg-logo"}
              type="submit"
            >
              {t("login.connect")}
            </Button>

            <hr className={"m-3"} />
            <Form.Group className="mb-3">
              <Form.Text className="text-muted">{t("login.open_account")}</Form.Text>
            </Form.Group>
            <Link to={"/send-invitation"}>
              <Button
                className={"w-100 h-12 rounded-2xl border-none text-base font-semibold cursor-pointer bg-logo"}
                type="submit"
              >
                {t("login.ask_invitation")}
              </Button>
            </Link>
          </div>
        </Form>
      </div>
    </>
  );
}

export default Login;
