import React from "react";

export default function LoadingSpinner(props) {
  const { style } = props;
  return (
    <div className="spinner-container flex items-center justify-center">
      <div className="loading-spinner" style={style || { width: 48, height: 48, marginTop: 30 }}></div>
    </div>
  );
}
