import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import { firstLetterCapitalize } from "../../_helpers/format";
import { CheckOutlined, CloseOutlined, CloudUploadOutlined } from "@ant-design/icons";
import DefaultDropdown from "../_shared/input/Dropdown";
import { getUsersPaySlipStatus, UpdateUsersPaySlipStatus } from "../../_api/user";
import { getDocument } from "../../_api/document";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import LoadingSpinner from "../_shared/loader/loader";
import { FileUploader } from "react-drag-drop-files";
import Form from "react-bootstrap/Form";
import Switch from "react-switch";
const fileTypes = ["PDF"];

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const BulkPaySlip = (props) => {
  const { t, i18n } = useTranslation();
  const availableMonths = [
    { value: 1, label: t("month.january") },
    { value: 2, label: t("month.february") },
    { value: 3, label: t("month.march") },
    { value: 4, label: t("month.april") },
    { value: 5, label: t("month.may") },
    { value: 6, label: t("month.june") },
    { value: 7, label: t("month.july") },
    { value: 8, label: t("month.august") },
    { value: 9, label: t("month.september") },
    { value: 10, label: t("month.october") },
    { value: 11, label: t("month.november") },
    { value: 12, label: t("month.december") },
  ];
  const years = [];
  var currentYear = new Date().getFullYear();
  var currentMonth = availableMonths.find((el) => el.value - 1 === new Date().getMonth());
  var min = currentYear - 5;

  for (var i = currentYear; i >= min; i--) {
    for (var j = 11; j >= 0; j--) {
      if (currentYear === i && j >= currentMonth.value) {
        continue;
      }
      years.push({
        value: i + "_" + (availableMonths[j].value - 1),
        label: availableMonths[j].label.substr(0, 3) + " " + i,
      });
    }
  }
  // File Handle
  const [selectedFileUpload, setSelectedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [isTestingFile, setIsTestingFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [paySlipStatus, setPaySlipStatus] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [isReplaceDelete, setIsReplaceDelete] = useState(false);

  const [pdfString, setPdfString] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageNumber(numPages);
  };
  const { show, onHide, users, user } = props;
  const onHideModal = () => {
    setFile(null);
    setIsTestingFile(false);
    setSelectedFile(null);
    setPaySlipStatus({});
    setPdfString("");
    onHide();
  };
  const [dropdownValue, setDropdownValue] = useState({
    value: currentYear + "_" + (currentMonth.value - 1),
    label: currentMonth.label.substr(0, 3) + " " + currentYear,
  });

  const validateForm = (file) => {
    const payload = {
      month: parseInt(dropdownValue.value.split("_")[1]),
      year: parseInt(dropdownValue.value.split("_")[0]),
      file: file,
    };
    UpdateUsersPaySlipStatus(payload, i18n.language).then((resp) => {
      console.log(resp);
    });
  };

  const uploadFile = async (file) => {
    setIsTestingFile(true);
    setIsLoading(true);
    setIsLoading(false);
  };

  const onChangeUser = async (user, _paySlipStatus) => {
    setIsLoading(true);
    setPdfString(null);

    setSelectedUser(user);
    const dropdownYear = parseInt(dropdownValue.value.split("_")[0]);
    const dropdownMonth = parseInt(dropdownValue.value.split("_")[1]);
    if (Object.keys(_paySlipStatus).length > 0 && _paySlipStatus[user.id][dropdownYear][dropdownMonth + 1]) {
      const blob = await getDocument(user.id, dropdownYear, dropdownMonth + 1);
      let reader = new FileReader();
      let base64String;
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        base64String = reader.result;
        setPdfString(base64String.substr(base64String.indexOf(",") + 1));
      };
      setIsLoading(false);
    } else {
      setPdfString(null);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (users && show) {
      const filteredUsers = users.filter((currentUser) => {
        const year = parseInt(dropdownValue.value.split("_")[0]);
        const month = parseInt(dropdownValue.value.split("_")[1]);
        const startedContract = new Date(currentUser?.contract_start * 1000);
        if (startedContract <= new Date(year, month)) {
          return currentUser;
        } else {
          if (startedContract.getFullYear() === year && startedContract.getMonth() === month) {
            return currentUser;
          }
        }
      });

      getUsersPaySlipStatus(
        filteredUsers.map((u) => u.id),
        i18n.language
      ).then((resp) => {
        setPaySlipStatus(resp.data.data);
        setFilteredUsers(filteredUsers);
        if (filteredUsers.length > 0) {
          setSelectedUser(filteredUsers[0]);
          onChangeUser(filteredUsers[0], resp.data.data);
        }
      });
    }
  }, [dropdownValue, users, show]);

  let successPayroll = 0;
  Object.keys(paySlipStatus).map((id) => {
    const dropdownYear = parseInt(dropdownValue.value.split("_")[0]);
    const dropdownMonth = parseInt(dropdownValue.value.split("_")[1]) + 1;
    if (Object.hasOwn(paySlipStatus[id], dropdownYear) && paySlipStatus[id][dropdownYear][dropdownMonth]) {
      successPayroll = successPayroll + 1;
    }
  });

  const onChangeDropdown = (value) => {
    setDropdownValue(value);
    setFile(null);
    setIsTestingFile(false);
    setSelectedFile(null);
    setPdfString(null);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => onHideModal()}
        backdrop="static"
        keyboard={false}
        className="w-100 h-100"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="flex justify-between w-100">
            <div>
              <div className="font-bold text-xl">
                Gestion des paies
                <div className="font-light italic text-base">
                  You can upload either payroll file containing all payroll or individual payroll.
                </div>
              </div>
            </div>
            <div className="flex">
              <div>
                {successPayroll !== filteredUsers.length && (
                  <FileUploader
                    multiple={false}
                    label={"Déposer votre fichier du mois."}
                    handleChange={(e) => validateForm(e)}
                    fileOrFiles={file}
                    name="file"
                    types={fileTypes}
                  />
                )}
              </div>
              <div className="text-lg">
                <DefaultDropdown
                  placeholder={t("create.user.civility.choose")}
                  options={years}
                  value={dropdownValue}
                  onChange={(value) => onChangeDropdown(value)}
                />
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="flex h-100">
          <div className="w-2/5 h-100">
            <ListGroup as="ol" numbered className="h-100 overflow-y-scroll">
              <div className="flex pb-3">
                <div className="text-left w-100">
                  <span className=" ml-5">Liste des salariés</span>
                </div>
                <div className="text-right w-100">
                  <span className="fw-bold  mr-5">
                    {successPayroll}/{filteredUsers.length}
                  </span>
                </div>
              </div>
              {filteredUsers && filteredUsers.length > 0 && Object.keys(paySlipStatus).length > 0 ? (
                filteredUsers.map((user) => {
                  const dropdownYear = parseInt(dropdownValue.value.split("_")[0]);
                  const dropdownMonth = parseInt(dropdownValue.value.split("_")[1]);
                  const isActive = selectedUser.id === user.id;

                  return (
                    <ListGroup.Item
                      as="li"
                      className={"d-flex justify-content-between align-items-start ml-3 mr-3 cursor-pointer"}
                      style={isActive ? { background: "#ddd" } : {}}
                      onClick={() => onChangeUser(user, paySlipStatus)}
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">{firstLetterCapitalize(user.last_name + " " + user.first_name)}</div>
                        ID : {user.social_security}
                      </div>
                      <Badge
                        bg={
                          Object.hasOwn(paySlipStatus[user.id], dropdownYear) &&
                          paySlipStatus[user.id][dropdownYear][dropdownMonth + 1]
                            ? "success"
                            : "danger"
                        }
                        pill
                      >
                        {Object.hasOwn(paySlipStatus[user.id], dropdownYear) &&
                        paySlipStatus[user.id][dropdownYear][dropdownMonth + 1] ? (
                          <CheckOutlined twoToneColor="#eb2f96" className="text-xs" />
                        ) : (
                          <CloseOutlined twoToneColor="#eb2f96" className="text-xs" />
                        )}
                      </Badge>
                    </ListGroup.Item>
                  );
                })
              ) : (
                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start mb-3 ml-3 mr-3">
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">Aucun utilisateur</div>
                    Veuillez en ajouter pour utiliser cette fonctionnalité.
                  </div>
                </ListGroup.Item>
              )}
            </ListGroup>
          </div>

          {selectedFileUpload || pdfString ? (
            <div className="text-center w-3/5 flex justify-center items-center mb-3 ml-3 mr-3 overflow-y-auto max-h-full">
              <Document file={`data:application/pdf;base64,${pdfString}`} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.apply(null, Array(pageNumber))
                  .map((x, i) => i + 1)
                  .map((page) => (
                    <Page pageNumber={page} />
                  ))}
              </Document>
            </div>
          ) : (
            <div className="relative text-center w-3/5 flex justify-center items-center bg-slate-50 mb-3 ml-3 mr-3 rounded-md cursor-pointer">
              <div>
                <input
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                    uploadFile(e.target.files[0]);
                  }}
                  value={selectedFileUpload}
                  type="file"
                  name="fileToUpload"
                  id="fileToUpload"
                  size="1"
                  className={"cursor-pointer opacity-0 w-100 h-100 opacity-1 absolute top-0 left-0 bottom-0 right-0"}
                />
                {isLoading && <LoadingSpinner />}
                {!isLoading && (
                  <div className="">
                    <CloudUploadOutlined className={"text-5xl align-middle"} />
                    <div>Upload your payslip file</div>
                  </div>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="flex justify-end">
          {/* <Form.Group className="mb-1 mr-10" controlId="formBasicEmail">
            <Form.Label className={"italic align-top mr-3"}>Remplace et annule</Form.Label>
            <Switch
              disabled={isLoading}
              onChange={(e) => setIsReplaceDelete(!isReplaceDelete)}
              checked={isReplaceDelete}
            />
          </Form.Group> */}
          <div className="flex right">
            {successPayroll !== filteredUsers.length && (
              <Button
                disabled={isLoading}
                className={"mb-2 mr-2 h-10 rounded-2xl border-none text-base font-semibold cursor-pointer bg-logo"}
                onClick={() => validateForm()}
              >
                Valider l'envoi des paies
              </Button>
            )}
            <Button
              disabled={isLoading}
              className={"mb-2 h-10 rounded-2xl border-none text-base font-semibold cursor-pointer bg-logo"}
              onClick={() => onHideModal()}
            >
              {t("back")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
