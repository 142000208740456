import axios from "axios";
import { BACK_END_URL, privateHeader, onError } from "./_shared";

export const login = (payload, language) => {
  return axios.post(BACK_END_URL + "/auth/login", payload, {
    headers: { "Accept-Language": language },
  });
};

export const getInfos = (token, language) => {
  return axios
    .get(BACK_END_URL + "/auth/infos", {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};
