import DefaultDropdown from "../_shared/input/Dropdown";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { options } from "../../_helpers/constants";
import { getStatsRH } from "../../_api/user";

const StatsRHTab = (props) => {
  const { t, i18n } = useTranslation();
  const { user } = props;

  const [payroll, setPayroll] = useState(null);
  const [soldeToutCompte, setSoldeToutCompte] = useState(false);
  const [contract, setContract] = useState(false);
  const [contractModification, setContractModification] = useState(false);
  const [mutual, setMutual] = useState(false);
  const [stopWork, setStopWork] = useState(false);
  const [decompteHour, setDecompteHour] = useState(false);

  const availableMonths = [
    { value: "1", label: t("month.january") },
    { value: "2", label: t("month.february") },
    { value: "3", label: t("month.march") },
    { value: "4", label: t("month.april") },
    { value: "5", label: t("month.may") },
    { value: "6", label: t("month.june") },
    { value: "7", label: t("month.july") },
    { value: "8", label: t("month.august") },
    { value: "9", label: t("month.september") },
    { value: "10", label: t("month.october") },
    { value: "11", label: t("month.november") },
    { value: "12", label: t("month.december") },
  ];
  const [displayPayrollYear, setDisplayPayrollYear] = useState({
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  });

  useEffect(() => {
    if (props.tab === "stats") {
      const token = localStorage.getItem("TOKEN");

      getStatsRH(token, user.id, i18n.language).then((resp) => {
        setPayroll(resp.data.data.payroll_presence);
        setSoldeToutCompte(resp.data.data.solde);
        setContract(resp.data.data.contract);
        setContractModification(resp.data.data.contract_modification);
        setMutual(resp.data.data.mutual);
        setStopWork(resp.data.data.stop_work);
        setDecompteHour(resp.data.data.decompte_hour);
      });
    }
  }, [props.tab, user, i18n.language]);

  const contractStart = new Date(parseInt(user.contract_start * 1000));
  const optionsYearFiltered = options.filter((year) => year.value >= contractStart.getFullYear());
  return (
    <>
      <h2 className={"font-semibold"}>{t("payroll_presence")}</h2>
      <div className={"mt-2 mb-2"}>
        <div className={"flex w-100"}>
          <div className={"w-100"}>
            {t("payroll_of")}{" "}
            <DefaultDropdown
              options={optionsYearFiltered}
              onChange={(value) => setDisplayPayrollYear(value)}
              value={displayPayrollYear}
            />
          </div>
        </div>
        <table className={"mt-2 mb-4"}>
          <thead>
            <tr>
              <td className={"p-1"}>{availableMonths[0].label}</td>
              <td className={"p-1"}>{availableMonths[1].label}</td>
              <td className={"p-1"}>{availableMonths[2].label}</td>
              <td className={"p-1"}>{availableMonths[3].label}</td>
              <td className={"p-1"}>{availableMonths[4].label}</td>
              <td className={"p-1"}>{availableMonths[5].label}</td>
              <td className={"p-1"}>{availableMonths[6].label}</td>
              <td className={"p-1"}>{availableMonths[7].label}</td>
              <td className={"p-1"}>{availableMonths[8].label}</td>
              <td className={"p-1"}>{availableMonths[9].label}</td>
              <td className={"p-1"}>{availableMonths[10].label}</td>
              <td className={"p-1"}>{availableMonths[11].label}</td>
            </tr>
          </thead>
          <tbody>
            {payroll && (
              <tr>
                <td
                  className={`h-1.5 ${payroll[displayPayrollYear.value]["1"] ? "bg-emerald-400" : "bg-rose-600"}`}
                ></td>
                <td
                  className={`h-1.5 ${payroll[displayPayrollYear.value]["2"] ? "bg-emerald-400" : "bg-rose-600"}`}
                ></td>
                <td
                  className={`h-1.5 ${payroll[displayPayrollYear.value]["3"] ? "bg-emerald-400" : "bg-rose-600"}`}
                ></td>
                <td
                  className={`h-1.5 ${payroll[displayPayrollYear.value]["4"] ? "bg-emerald-400" : "bg-rose-600"}`}
                ></td>
                <td
                  className={`h-1.5 ${payroll[displayPayrollYear.value]["5"] ? "bg-emerald-400" : "bg-rose-600"}`}
                ></td>
                <td
                  className={`h-1.5 ${payroll[displayPayrollYear.value]["6"] ? "bg-emerald-400" : "bg-rose-600"}`}
                ></td>
                <td
                  className={`h-1.5 ${payroll[displayPayrollYear.value]["7"] ? "bg-emerald-400" : "bg-rose-600"}`}
                ></td>
                <td
                  className={`h-1.5 ${payroll[displayPayrollYear.value]["8"] ? "bg-emerald-400" : "bg-rose-600"}`}
                ></td>
                <td
                  className={`h-1.5 ${payroll[displayPayrollYear.value]["9"] ? "bg-emerald-400" : "bg-rose-600"}`}
                ></td>
                <td
                  className={`h-1.5 ${payroll[displayPayrollYear.value]["10"] ? "bg-emerald-400" : "bg-rose-600"}`}
                ></td>
                <td
                  className={`h-1.5 ${payroll[displayPayrollYear.value]["11"] ? "bg-emerald-400" : "bg-rose-600"}`}
                ></td>
                <td
                  className={`h-1.5 ${payroll[displayPayrollYear.value]["12"] ? "bg-emerald-400" : "bg-rose-600"}`}
                ></td>
              </tr>
            )}
          </tbody>
        </table>
        <table className="w-90 mb-4 border text-sm" id={"documents"}>
          <thead>
            <tr>
              <td className="font-semibold">Liste des documents RH</td>
              <td className="font-semibold">Déposé</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t("file.type.employees.contract")}</td>
              <td className={`h-5 w-5 rounded-md ${contract ? "bg-emerald-400" : "bg-rose-600"}`}></td>
            </tr>
            <tr>
              <td>{t("file.type.insurance.contract")}</td>
              <td className={`h-5 w-5 rounded-md ${mutual ? "bg-emerald-400" : "bg-rose-600"}`}></td>
            </tr>
            <tr>
              <td>{t("file.type.counting.hour")}</td>
              <td className={`h-5 w-5 rounded-md ${decompteHour ? "bg-emerald-400" : "bg-rose-600"}`}></td>
            </tr>
            <tr>
              <td>{t("file.type.solde")}</td>
              <td className={`h-5 w-5 rounded-md ${soldeToutCompte ? "bg-emerald-400" : "bg-rose-600"}`}></td>
            </tr>
            <tr>
              <td>{t("file.type.stop.work")}</td>
              <td className={`h-5 w-5 rounded-md ${stopWork ? "bg-emerald-400" : "bg-rose-600"}`}></td>
            </tr>
            <tr>
              <td>{t("file.type.employees.contract.modification")}</td>
              <td className={`h-5 w-5 rounded-md ${contractModification ? "bg-emerald-400" : "bg-rose-600"}`}></td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default StatsRHTab;
