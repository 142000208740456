import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import ManagerSettings from "./ManagerSettings";
import DistributorSettings from "./DistributorSettings";
import UserSettings from "./UserSettings";

const Settings = () => {
  const { user } = useOutletContext();

  useEffect(() => {}, []);

  return (
    <div>
      {user.user_type === "MANAGER" && <ManagerSettings user={user} />}
      {user.user_type === "DISTRIB" && <DistributorSettings user={user} />}
      {user.user_type === "USER" && <UserSettings user={user} />}
    </div>
  );
};

export default Settings;
