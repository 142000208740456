import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { deleteUser, updateUser } from "../../_api/user";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { firstLetterCapitalize } from "../../_helpers/format";
import Switch from "react-switch";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Selector from "../_shared/input/MultiSelector";

const InfosTab = (props) => {
  const { t, i18n } = useTranslation();

  const mapperRole = {
    MANAGER: t("role.manager"),
    USER: t("role.user"),
  };

  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);

  const { user, setUsers, setSelectedUser, setFilteredUsers } = props;

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [internalID, setInternalID] = useState("");
  const [socialSecurity, setSocialSecurity] = useState("");
  const [message, setMessage] = useState("");
  const [contractStart, setContractStart] = useState("");
  const [contractEnd, setContractEnd] = useState("");
  const [companies, setCompanies] = useState([]);

  const [receiveByMail, setReceiveByMail] = useState(false);
  const [streetNumber, setStreetNumber] = useState("");
  const [streetExtension, setStreetExtension] = useState("");
  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");

  const [selectedCompanies, setSelectedCompanies] = useState([]);

  useEffect(() => {
    const companiesOptions = user?.authorizedCompanies.map((company) => {
      return { value: company.id, label: company.name + " - " + company.siren };
    });
    const companiesSelected = user?.selectCompanies.map((company) => {
      return { value: company.id, label: company.name + " - " + company.siren };
    });
    setCompanies(companiesOptions);
    setSelectedCompanies(companiesSelected);
    setLastName(user?.last_name);
    setFirstName(user?.first_name);
    setEmail(user?.email);
    setInternalID(user?.internal_id);
    setContractEnd(user?.contract_end ? new Date(user?.contract_end * 1000).toLocaleDateString() : "");
    setContractStart(user?.contract_start ? new Date(user?.contract_start * 1000).toLocaleDateString() : "");
    setReceiveByMail(user?.is_using_laposte);
    setSocialSecurity(user?.social_security);
    setStreetNumber(user?.number);
    setStreetExtension(user?.extension);
    setStreet(user?.street_name);
    setZipCode(user?.zip_code);
    setCity(user?.city);
  }, [user]);
  const updateUserClic = (e) => {
    e.preventDefault();

    const payload = {
      last_name: lastName,
      first_name: firstName,
      email,
      companies: selectedCompanies,
      internal_id: internalID,
      socialSecurity: socialSecurity,
      contract_end: contractEnd,
      contract_start: contractStart,
      streetNumber,
      streetExtension,
      street,
      zipCode,
      city,
      receiveByMail,
    };

    updateUser(localStorage.getItem("TOKEN"), payload, i18n.language, user.id)
      .then((resp) => {
        setEditMode(false);
        setUsers(resp.data.data.users);
        setFilteredUsers(resp.data.data.users);
        setSelectedUser(resp.data.data.user);
        setMessage("");
      })
      .catch((error) => {
        setMessage(error.response.data.message);
      });
  };

  const DeleteModeDiv = (props) => {
    const { user, setUsers, setSelectedUser } = props;
    useEffect(() => {}, []);

    const deleteUserAction = () => {
      const token = localStorage.getItem("TOKEN");
      deleteUser(token, {}, i18n.language, user.id)
        .then((resp) => {
          setDeleteMode(false);
          setUsers(resp.data.data);
          setSelectedUser(null);
          setFilteredUsers(resp.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    return (
      <>
        <Alert key={"danger"} variant={"light"}>
          <p className={"font-semibold"}>{t("sure.delete.user")}</p>
          <br />
          <Button
            className={"w-100 h-12 border-none rounded-2xl text-base font-semibold cursor-pointer bg-logo"}
            onClick={() => setDeleteMode(false)}
          >
            {t("back")}
          </Button>{" "}
          <Button
            className={"w-100 h-12 border-none rounded-2xl text-base font-semibold cursor-pointer bg-logo"}
            onClick={() => deleteUserAction()}
          >
            {t("sure.delete.user.validation")}
          </Button>{" "}
        </Alert>
      </>
    );
  };

  return (
    <>
      {user && (
        <div className={"flex justify-between"}>
          <div>
            {deleteMode && (
              <DeleteModeDiv
                setSelectedUser={setSelectedUser}
                setUsers={setUsers}
                setDeleteMode={setDeleteMode}
                user={user}
              />
            )}
            {!deleteMode && (
              <div>
                <ul>
                  <li>
                    <Form.Group className="" controlId="formBasicEmail">
                      <Form.Label>
                        <span className={"font-semibold"}>
                          {editMode ? t("user.last_name") : t("user.full_name")} :{" "}
                        </span>
                        {user &&
                          !editMode &&
                          firstLetterCapitalize(user?.last_name + " " + user?.first_name || user.name)}
                        {editMode && (
                          <Form.Control
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                            required
                            type="text"
                            placeholder={""}
                          />
                        )}
                      </Form.Label>
                    </Form.Group>
                  </li>
                  {editMode && (
                    <li>
                      <Form.Group className="" controlId="formBasicEmail">
                        <Form.Label>
                          <span className={"font-semibold"}>{t("user.first_name")} : </span>
                          {user && !editMode && firstLetterCapitalize(user?.first_name || user.name)}
                          {editMode && (
                            <Form.Control
                              onChange={(e) => setFirstName(e.target.value)}
                              value={firstName}
                              required
                              type="text"
                              placeholder={""}
                            />
                          )}
                        </Form.Label>
                      </Form.Group>
                    </li>
                  )}

                  <li>
                    <Form.Group className="" controlId="formBasicEmail">
                      <Form.Label>
                        <span className={"font-semibold"}>Email : </span>
                        {!editMode && user.email}
                        {editMode && (
                          <Form.Control
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                            type="email"
                            placeholder={""}
                          />
                        )}
                      </Form.Label>
                    </Form.Group>
                  </li>
                  {user.user_type !== "MANAGER" && (
                    <li>
                      <Form.Group className="" controlId="formBasicEmail">
                        <Form.Label>
                          {!editMode && (
                            <span className={"font-semibold"}>{t("create.user.contract_start.simple") + " "}: </span>
                          )}
                          {!editMode && new Date(user.contract_start * 1000).toLocaleDateString()}
                          {editMode && <span className={"font-semibold"}>{t("create.user.contract_start")}: </span>}
                          {editMode && (
                            <Form.Control
                              onChange={(e) => setContractStart(e.target.value)}
                              value={contractStart}
                              required
                              type="text"
                              placeholder={""}
                            />
                          )}
                        </Form.Label>
                      </Form.Group>
                    </li>
                  )}
                  {user.user_type !== "MANAGER" && (
                    <li>
                      <Form.Group className="" controlId="formBasicEmail">
                        {!editMode && user.contract_end && (
                          <Form.Label>
                            <span className={"font-semibold"}>{t("create.user.contract_end.simple") + " "}: </span>
                          </Form.Label>
                        )}
                        {!editMode && user.contract_end && new Date(user.contract_end * 1000).toLocaleDateString()}

                        {editMode && (
                          <Form.Label>
                            <span className={"font-semibold"}>{t("create.user.contract_end")} : </span>
                            <Form.Control
                              onChange={(e) => setContractEnd(e.target.value)}
                              value={contractEnd}
                              required
                              type="text"
                              placeholder={""}
                            />
                          </Form.Label>
                        )}
                      </Form.Group>
                    </li>
                  )}
                  {user && user.internal_id && (
                    <li>
                      <Form.Group className="" controlId="formBasicEmail">
                        <Form.Label>
                          <span className={"font-semibold"}>{t("user.internal_id")} : </span>
                          {!editMode && user.internal_id.split("_").slice(-1)}
                          {editMode && (
                            <Form.Control
                              onChange={(e) => setInternalID(e.target.value)}
                              value={internalID.split("_").slice(-1)}
                              required
                              type="text"
                              placeholder={""}
                            />
                          )}
                        </Form.Label>
                      </Form.Group>
                    </li>
                  )}
                  {user && user.user_type !== "MANAGER" && editMode && (
                    <li>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          <span className={"font-semibold"}>{t("user.social_security")} : </span>
                          <Form.Control
                            onChange={(e) => setSocialSecurity(e.target.value)}
                            value={socialSecurity || ""}
                            required
                            type="text"
                            placeholder={""}
                          />
                        </Form.Label>
                      </Form.Group>
                    </li>
                  )}
                  {user && user.user_type !== "MANAGER" && !editMode && user.social_security && (
                    <li>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          <span className={"font-semibold"}>{t("user.social_security")} : </span>
                          {user.social_security}
                        </Form.Label>
                      </Form.Group>
                    </li>
                  )}

                  {!editMode && (
                    <li>
                      <Form.Group className="" controlId="formBasicEmail">
                        <Form.Label>
                          <span className={"font-semibold"}>{t("user.created_at")} : </span>
                          {new Date(user.created_at * 1000).toLocaleDateString()}
                        </Form.Label>
                      </Form.Group>
                    </li>
                  )}
                  {!editMode && (
                    <li>
                      <Form.Group className="" controlId="formBasicEmail">
                        <Form.Label>
                          <span className={"font-semibold"}>{t("user.last_connect")} : </span>
                          {user.last_login
                            ? new Date(user.last_login * 1000).toLocaleDateString()
                            : t("status.waiting.connection")}
                        </Form.Label>
                      </Form.Group>
                    </li>
                  )}
                  {editMode && (
                    <Form.Group className="" controlId="formBasicEmail">
                      <Form.Label>
                        <span className={"font-semibold"}>{t("user.is_archived")} :</span>
                      </Form.Label>
                      <br />
                      <Switch onChange={(value) => setReceiveByMail(value)} checked={receiveByMail} />
                    </Form.Group>
                  )}
                  {editMode && receiveByMail && (
                    <>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <div className={"flex"}>
                          <Form.Label className={"w-50"}>{t("user.settings.street.number")}*</Form.Label>
                          <Form.Label className={"w-50"}>{t("user.settings.street.name")}*</Form.Label>
                        </div>
                        <div className={"flex"}>
                          <Form.Control
                            onChange={(e) => setStreetNumber(e.target.value)}
                            value={streetNumber}
                            required
                            type="text"
                            placeholder={"5"}
                          />
                          <Form.Control
                            onChange={(e) => setStreet(e.target.value)}
                            value={street}
                            required
                            type="text"
                            placeholder={t("user.settings.street.name")}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>{t("user.settings.street.extension")}</Form.Label>
                        <Form.Control
                          onChange={(e) => setStreetExtension(e.target.value)}
                          value={streetExtension}
                          required
                          type="text"
                          placeholder={t("login.email.placeholder")}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicPassword"></Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <div className={"flex w-100"}>
                          <Form.Label className={"w-50"}>{t("user.settings.city.zipcode")}*</Form.Label>
                          <Form.Label className={"w-50"}>{t("user.settings.street.city")}*</Form.Label>
                        </div>
                        <div className={"flex"}>
                          <Form.Control
                            onChange={(e) => setZipCode(e.target.value)}
                            value={zipCode}
                            required
                            type="text"
                            placeholder={t("login.email.placeholder")}
                          />
                          <Form.Control
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                            required
                            type="text"
                            placeholder={t("login.email.placeholder")}
                          />
                        </div>
                      </Form.Group>
                    </>
                  )}
                  <Selector
                    isMulti={user.user_type === "MANAGER"}
                    disabled={!editMode}
                    onChange={setSelectedCompanies}
                    value={selectedCompanies}
                    options={companies}
                    name={t("settings.users.company.autorized") + " :"}
                  />

                  {!editMode && user && user.internal_id && (
                    <li>
                      <Form.Group className="" controlId="formBasicEmail">
                        <Form.Label>
                          <span className={"font-semibold"}>{t("user.is_archived")} :</span>
                        </Form.Label>
                        <br />
                        <Switch disabled={true} onChange={null} checked={user.is_using_laposte} />
                      </Form.Group>
                    </li>
                  )}
                  <Form.Text className="text-muted text-red-600">{message}</Form.Text>
                  {editMode && (
                    <Button
                      onClick={(e) => updateUserClic(e)}
                      className={
                        "mb-2 w-100 border-none h-12 rounded-2xl text-base font-semibold cursor-pointer bg-logo "
                      }
                    >
                      {t("my.account.modify.user")}
                    </Button>
                  )}
                </ul>
              </div>
            )}
          </div>
          {user.user_type !== 'ADMIN"' && (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div>
                <EditOutlined
                  onClick={() => setEditMode(!editMode)}
                  className={"ml-3 mr-3 cursor-pointer text-2xl hover:text-sky-400"}
                />
                <DeleteOutlined
                  onClick={() => setDeleteMode(!deleteMode)}
                  className={"ml-3 mr-3 cursor-pointer text-2xl hover:text-sky-400"}
                />
              </div>
            </Form.Group>
          )}
        </div>
      )}
    </>
  );
};

export default InfosTab;
