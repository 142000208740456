import { useTranslation } from "react-i18next";

export const TabAction = (props) => {
  const { t } = useTranslation();
  const { active, icon, label, click } = props;

  return (
    <div
      className={`w-48 h-16 border-solid border-black border text-center " ${
        !active ? "cursor-not-allowed bg-slate-200 opacity-50" : "cursor-pointer hover:bg-white"
      }`}
      onClick={() => {
        if (click) {
          click();
        }
      }}
    >
      {icon}
      <h2 className={"mt-1"}>{t("document.action." + label)}</h2>
    </div>
  );
};

export default TabAction;
