import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { postDocument } from "../../_api/document";
import Form from "react-bootstrap/Form";
import DefaultDropdown from "../_shared/input/Dropdown";
import { FileUploader } from "react-drag-drop-files";
import Button from "react-bootstrap/Button";
import { Alert } from "react-bootstrap";
import { options } from "../../_helpers/constants";

const fileTypes = ["JPG", "PNG", "GIF", "PDF", "XLS", "XLSX"];

const DocumentsTab = (props) => {
  const { t, i18n } = useTranslation();

  const documentOptions = [
    { value: "PAYROLL", label: t("file.type.payroll") },
    { value: "INSURANCE_CONTRACT", label: t("file.type.insurance.contract") },
    { value: "EMPLOYEE_CONTRACT", label: t("file.type.employees.contract") },
    {
      value: "EMPLOYEE_CONTRACT_MODIFICATION",
      label: t("file.type.employees.contract.modification"),
    },
    { value: "SOLDE", label: t("file.type.solde") },
    { value: "STOP_WORK", label: t("file.type.stop.work") },
    // {"value": "SOCIAL", "label": t("file.type.social")},
    { value: "COUNTING_HOUR", label: t("file.type.counting.hour") },
    // {"value": "REGISTRY", "label": t("file.type.unique.registry")},
    { value: "OTHERS", label: t("file.type.others") },
  ];

  const availableMonths = [
    { value: "1", label: t("month.january") },
    { value: "2", label: t("month.february") },
    { value: "3", label: t("month.march") },
    { value: "4", label: t("month.april") },
    { value: "5", label: t("month.may") },
    { value: "6", label: t("month.june") },
    { value: "7", label: t("month.july") },
    { value: "8", label: t("month.august") },
    { value: "9", label: t("month.september") },
    { value: "10", label: t("month.october") },
    { value: "11", label: t("month.november") },
    { value: "12", label: t("month.december") },
  ];
  const { user } = props;
  const [documentType, setDocumentType] = useState(documentOptions[0]);
  const contractStart = new Date(parseInt(user?.contract_start * 1000));
  const optionsYearFiltered = options.filter((year) => year.value >= contractStart.getFullYear());
  const [month, setMonth] = useState(availableMonths[0]);
  const [year, setYear] = useState(optionsYearFiltered[0]);

  const [isUploadedSuccess, setSuccessFullUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };

  useEffect(() => {
    setSuccessFullUpload(false);
    setIsLoading(false);
    setFile(null);
    setDocumentType(documentOptions[0]);
    setMonth(availableMonths[0]);
    setYear(optionsYearFiltered[0]);
  }, [props.tab]);

  const submitNewDoc = (e) => {
    setSuccessFullUpload(false);
    setIsLoading(true);

    e.preventDefault();
    const payload = {
      user: user,
      key: `${user.admin_id}/USERS/${user.id}/`,
      document_type: documentType,
      file: file,
      year: year,
      month: month,
    };
    const token = localStorage.getItem("TOKEN");

    postDocument(token, payload, i18n.language)
      .then((response) => {
        if (response.status === 200) {
          setSuccessFullUpload(true);
          setFile(null);
          setDocumentType(documentOptions[0]);
          setMonth(availableMonths[0]);
          setYear(optionsYearFiltered[0]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <ul>
        <ul>
          {isUploadedSuccess && <Alert variant={"warning"}>{"Your upload is a success !"}</Alert>}
          {isLoading && <Alert variant={"warning"}>{"Uploading ..."}</Alert>}
          <Form>
            <li>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className={"font-semibold"}>{t("app.users.import.document")}</Form.Label>
                <DefaultDropdown
                  options={documentOptions}
                  onChange={(value) => {
                    setDocumentType(value);
                    setFile(null);
                  }}
                  value={documentType}
                />{" "}
              </Form.Group>
              {documentType.value === "PAYROLL" && (
                <>
                  <DefaultDropdown options={availableMonths} onChange={(value) => setMonth(value)} value={month} />
                  <DefaultDropdown options={optionsYearFiltered} onChange={(value) => setYear(value)} value={year} />
                </>
              )}
            </li>

            <li>
              <FileUploader
                multiple={false}
                label={t("app.users.import.dropzone")}
                handleChange={handleChange}
                fileOrFiles={file}
                name="file"
                types={fileTypes}
              />
            </li>
            <li>
              <Button
                disabled={isLoading || !file}
                className={
                  "mb-2 w-100 border-none h-12 rounded-2xl text-base font-semibold cursor-pointer bg-logo disabled:bg-appColor"
                }
                onClick={submitNewDoc}
              >
                {t("import")}
              </Button>
            </li>
          </Form>
        </ul>
      </ul>
    </>
  );
};

export default DocumentsTab;
