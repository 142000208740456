import { useEffect, useState, useRef } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  FolderOutlined,
  ArrowLeftOutlined,
  ImportOutlined,
  ExportOutlined,
  FolderAddOutlined,
} from "@ant-design/icons";
import Loupe from "../../_assets/loupe.png";
import { imageMapper } from "../../_helpers/constants";
import ReactSearchBox from "react-search-box";
import { getDocuments, postDocument, downloadDoc, deleteDocument, moveDocument } from "../../_api/document";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { TabAction } from "./Table/TabAction";
import { TableHead } from "./Table/TableHead";
import { TableFooter } from "./Table/TableFooter";
import { LeftSideMenuTable } from "./Table/LeftSideMenuTable";
import { RenameFileModal } from "./Modal/renameFile";
import LoadingSpinner from "../_shared/loader/loader";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";

const TableRow = ({
  getDoc,
  currentFolder,
  index,
  selectedRow,
  document,
  downloadDocument,
  distinctSource,
  filteredDocuments,
}) => {
  const { t, i18n } = useTranslation();

  const ref = useRef(null);

  const moveFile = (source, destination) => {
    const payload = {
      source,
      destination,
    };
    moveDocument(payload, i18n.language).then((resp) => {
      let split = currentFolder.key.split("/");
      split = split.filter((e) => e !== "");
      getDoc(split.slice(0, split.length).join("/") + "/");
    });
  };

  const [{}, drop] = useDrop({
    accept: "row",
    drop(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.id;
      const hoverIndex = document.id;
      const dragDoc = filteredDocuments.find((doc) => doc.id === document.id);
      const dropDoc = filteredDocuments.find((doc) => doc.id === item.id);
      if (!dragDoc.isFolder) {
        return;
      }

      if (dragIndex === hoverIndex) {
        return;
      }

      moveFile(dropDoc, dragDoc);
    },
  });

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "row",
    item: { id: document.id, index },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  }));

  drag(drop(ref));

  return (
    <tr
      ref={ref}
      className={document.isFolder ? "cursor-pointer h-8" : "cursor-move h-8"}
      style={selectedRow && selectedRow?.id === document.id ? { background: "#ddd" } : {}}
      key={document.id}
      onClick={(e) => downloadDocument(e, document)}
    >
      <td className={"pl-2 h-8 whitespace-nowrap max-w-xs text-ellipsis overflow-hidden"}>
        {document.isFolder && <FolderOutlined style={{ color: "rgba(0, 173, 181, 255)" }} className={"pr-2"} />}
        <span className="align-middle">{document.name}</span>
      </td>
      <td className={"pl-2 h-8"}>
        {!document.isFolder && new Date(document.created_at * 1000).toLocaleDateString()}{" "}
        {!document.isFolder && new Date(document.created_at * 1000).toLocaleTimeString([], { timeStyle: "short" })}
      </td>
      {distinctSource.length > 1 && <td className={"pl-2 h-8"}>{!document.isFolder && document.source}</td>}
      <td className={"pl-2 h-8 w-6 text-center"}>{!document.isFolder && parseInt(document.size) + "kb"}</td>
      <td className={"pl-2 h-8 w-6 text-center"}>
        {!document.isFolder && (
          <img
            src={imageMapper[document.mimetype]}
            height={20}
            width={20}
            alt={t("document.table.alt.document.type")}
          />
        )}
      </td>
    </tr>
  );
};
const MyDocuments = () => {
  const { t, i18n } = useTranslation();
  const { user, uploadList } = useOutletContext();

  const [documents, setDocuments] = useState([]);
  const [parentFolder, setParentFolder] = useState(null);
  const [historyFolder, setHistoryFolder] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [distinctSource, setDistinctSource] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [selectedFileUpload] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [isRenamingFile, setIsRenamingFile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const changeSelect = (value) => {
    const _documents = [];
    documents.forEach((document) => {
      if (document.name.toLowerCase().includes(value.toLowerCase())) {
        _documents.push(document);
      }
    });
    setFilteredDocuments(_documents);
  };

  const searchSelect = (record) => {
    setFilteredDocuments(documents.find((document) => document.includes() === record.item.key));
  };

  const getDoc = (key = null) => {
    setSelectedRow(null);
    setIsLoading(true);
    getDocuments(localStorage.getItem("TOKEN"), i18n.language, key)
      .then((resp) => {
        setDocuments(resp.data.data);
        setFilteredDocuments(resp.data.data);

        let sources = [];
        resp.data.data.map((val) => {
          sources.push(val.source);
          return "";
        });
        const _uniqueSource = (arr) => [...new Set(sources)];
        setDistinctSource(_uniqueSource);
        if (resp.data.data.length > 0 && !parentFolder && !currentFolder) {
          const indexToFolder = user_type === "USER" ? 3 : 2;
          setCurrentFolder({ key: resp.data.data[0].key.split("/").slice(0, indexToFolder).join("/") + "/" });
          setParentFolder(resp.data.data[0].key.split("/").slice(0, indexToFolder).join("/") + "/");
        }
        if (resp.data.data.length === 0) {
          const key =
            user.user_type === "MANAGER" ? `${user.admin_id}/CORPORATE/` : `${user.admin_id}/USERS/${user.id}/`;
          setCurrentFolder({ key: key });
          setParentFolder(key);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setDocuments([]);
      });
  };

  useEffect(() => {
    getDoc();
  }, [i18n.language]);

  useEffect(() => {
    if (uploadList.data) {
      setDocuments(uploadList.data);
      setFilteredDocuments(uploadList.data);
    }
  }, uploadList);

  const uploadFile = (file) => {
    setIsLoading(true);

    let key = currentFolder.key;
    if (!file) {
      key = key + "CHANGEID12466799";
    }
    const payload = {
      key: key,
      user: user,
      file: file,
      document_type: "OTHERS",
    };

    postDocument(localStorage.getItem("TOKEN"), payload, i18n.language, false)
      .then((response) => {
        let split = currentFolder.key.split("/");
        split = split.filter((e) => e !== "");
        getDoc(split.slice(0, split.length).join("/") + "/");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setDocuments([]);
      });
  };

  const downloadDocument = (e, document) => {
    const { id, name, isFolder } = document;
    switch (e.detail) {
      case 1:
        setSelectedRow(document);
        break;
      case 2: {
        if (isFolder) {
          setCurrentFolder(document);
          getDoc(document.key);
          historyFolder.push(document.name);
          setHistoryFolder(historyFolder);
          setSelectedRow(null);
        } else {
          downloadDoc(id, name, document.key);
          setSelectedRow(null);
        }
        break;
      }
      default:
        console.log("DEFAULT CASE");
    }
  };

  const getBackKey = () => {
    const toGoKey = currentFolder.key.split("/").slice(0, -2).join("/") + "/";
    historyFolder.pop();
    setHistoryFolder(historyFolder);

    setCurrentFolder({ key: toGoKey });
    getDoc(toGoKey);
    setSelectedRow(null);
  };

  const orderByHead = (column) => {
    const test = documents;
    if (column === "date") {
      setDocuments(test.sort((a, b) => parseFloat(b.created_at) - parseFloat(a.created_at)));
    }
    if (column === "size") {
      setDocuments(test.sort((a, b) => b.size - a.size));
    }
    if (column === "name") {
      const naturalCollator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });
      setDocuments(documents.sort((a, b) => naturalCollator.compare(a.name, b.name)));
    }
    if (column === "source") {
      const naturalCollator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });
      setDocuments(documents.sort((a, b) => naturalCollator.compare(a.source, b.source)));
    }
    setSelectedRow(null);
  };

  const deleteElement = () => {
    if (selectedRow) {
      const payload = {
        key: selectedRow.key,
      };
      deleteDocument(payload, i18n.language, selectedRow.id).then((resp) => {
        let split = selectedRow.key.split("/");
        split = split.filter((e) => e !== "");
        getDoc(split.slice(0, split.length - 1).join("/") + "/");
        setSelectedRow(null);
      });
    }
  };

  const renameElement = () => {
    if (selectedRow) {
      setIsRenamingFile(true);
    }
  };

  const { user_type } = user;
  const { create_folder, destroy_file, rename_file, export_file } = user?.rights;
  return (
    <div className={"h-100"}>
      <RenameFileModal
        show={isRenamingFile}
        selectedRow={selectedRow}
        getDoc={getDoc}
        onHide={() => setIsRenamingFile(false)}
      />
      <div className={"w-100 h-16 flex mt-1"}>
        <TabAction
          active={create_folder}
          label="new"
          click={() => uploadFile(null)}
          icon={<FolderAddOutlined className={"text-2xl"} />}
        />
        <TabAction
          active={destroy_file && selectedRow && selectedRow.can_be_deleted}
          label="delete"
          click={() => deleteElement()}
          icon={<DeleteOutlined className={"text-2xl"} />}
        />
        <TabAction
          active={rename_file && selectedRow}
          label="rename"
          click={() => renameElement()}
          icon={<EditOutlined className={"text-2xl"} />}
        />

        <div
          className={`relative truncate w-48 h-16 border-solid border-black border text-center hover:bg-white" ${
            !user?.rights.import_file ? "cursor-not-allowed bg-slate-200 opacity-50" : "cursor-pointer hover:bg-white"
          }`}
        >
          <ImportOutlined className={"text-2xl"} />
          <h2 className={"mt-1"}>{t("document.action.import")}</h2>
          <input
            onChange={(e) => uploadFile(e.target.files[0])}
            value={selectedFileUpload}
            type="file"
            name="fileToUpload"
            id="fileToUpload"
            size="1"
            className={"cursor-pointer opacity-0 w-48 h-16 opacity-1 absolute top-0 left-0 bottom-0 right-0"}
          />
        </div>
        <TabAction
          active={export_file && selectedRow}
          label="export"
          click={() => downloadDoc(selectedRow.id, selectedRow.name, selectedRow.key)}
          icon={<ExportOutlined className={"text-2xl"} />}
        />
        <div className={"w-100 flex justify-end h-16 border-y-2 border-x-2 pr-4 pt-2.5"}>
          <ReactSearchBox
            placeholder={t("document.action.search.placeholder")}
            data={filteredDocuments}
            onSelect={(record) => searchSelect(record)}
            onChange={(value) => changeSelect(value)}
            autoFocus
            leftIcon={
              <>
                <img src={Loupe} width={25} height={25} alt={"Loupe"} />
              </>
            }
            iconBoxSize="48px"
          />
        </div>
      </div>
      <hr className={""} />
      <div className={"flex h-5/6"}>
        <LeftSideMenuTable setCurrentFolder={setCurrentFolder} getDoc={getDoc} parentFolder={parentFolder} />
        <div className={"h-100 w-100 border-x-2 overflow-scroll"}>
          {parentFolder && currentFolder && parentFolder !== currentFolder.key && (
            <div className="h-7 flex">
              <div className="">
                <ArrowLeftOutlined className={"align-middle ml-2 mr-2"} onClick={() => getBackKey()} />
              </div>
              <div>
                <span className={"leading-7 italic text-sm"}>{historyFolder.join(" / ")}</span>
              </div>
            </div>
          )}
          {isLoading && (
            <table id={"documents"} className={"w-100 border overflow-scroll text-sm h-full"}>
              <tbody className="w-100 h-full overflow-hidden">
                <tr className="h-full">
                  <td colSpan={4} className="text-center h-full">
                    <LoadingSpinner style={{ width: 20, height: 20 }} />
                  </td>
                </tr>
              </tbody>
            </table>
          )}

          {!isLoading && (
            <table id={"documents"} className={"w-100 border overflow-scroll text-sm"}>
              <thead className={"max-h-2 h-7"}>
                <tr className={" bg-slate-100 border h-7.5"}>
                  <TableHead name="name" onClick={() => orderByHead("name")} />
                  <TableHead name="received" onClick={() => orderByHead("date")} />
                  {distinctSource.length > 1 && <TableHead name="source" />}
                  <TableHead name="size" onClick={() => orderByHead("size")} />
                  <TableHead name="format" />
                </tr>
              </thead>
              {currentFolder && (
                <tbody>
                  <DndProvider backend={HTML5Backend}>
                    {filteredDocuments &&
                      filteredDocuments.map((document, index) => {
                        if (
                          (currentFolder &&
                            currentFolder.key.split("/").length === document.key.split("/").length &&
                            !document.isFolder) ||
                          (currentFolder.key.split("/").length === document.key.split("/").length - 1 &&
                            document.isFolder)
                        ) {
                          return (
                            <TableRow
                              getDoc={getDoc}
                              currentFolder={currentFolder}
                              index={index}
                              row={selectedRow}
                              filteredDocuments={filteredDocuments}
                              selectedRow={selectedRow}
                              document={document}
                              downloadDocument={downloadDocument}
                              distinctSource={distinctSource}
                            />
                          );
                        }
                      })}
                  </DndProvider>
                  {documents.length === 0 && (
                    <tr>
                      <td colSpan={4} className={"text-center"}>
                        {t("document.table.no.data")}
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          )}
        </div>
      </div>
      <TableFooter user={user} />
    </div>
  );
};

export default MyDocuments;
