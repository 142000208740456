import axios from "axios";
import { BACK_END_URL, privateHeader, onError } from "./_shared";

export const sendContact = (token, payload, language) => {
  return axios
    .post(BACK_END_URL + "/mail/contact", payload, {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};
