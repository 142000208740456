import i18n from "i18next";

import Backend from "i18next-xhr-backend";
import translationEN from "./_assets/translations/en.json";
import translationFR from "./_assets/translations/fr.json";
import translationES from "./_assets/translations/es.json";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  es: {
    translation: translationES,
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    lng: "fr",
    fallbackLng: "fr",
    debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  })
  .then((r) => console.log(r));

export default i18n;
