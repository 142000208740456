import axios from "axios";
import { BACK_END_URL, privateHeader, onError } from "./_shared";

export const getCompanies = (token, language) => {
  return axios
    .get(BACK_END_URL + "/company", {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};
