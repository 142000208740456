import React from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";

const Selector = (props) => {
  const { name, options, disabled, onChange, value, placeholder, isMulti } = props;
  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label className={"font-semibold"}>{name}</Form.Label>
      <Select
        options={options}
        value={value}
        defaultValue={[options[0]]}
        isMulti={isMulti}
        onChange={onChange}
        name="colors"
        isDisabled={disabled}
        placeholder={placeholder || "Choisir ..."}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </Form.Group>
  );
};

export default Selector;
