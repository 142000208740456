import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { createUser } from "../../_api/user";
import { useTranslation } from "react-i18next";
import DefaultDropdown from "../_shared/input/Dropdown";
import { useOutletContext } from "react-router-dom";
import Selector from "../_shared/input/MultiSelector";
import { getCompanies } from "../../_api/company";

const UserCreateUpdate = (props) => {
  const { t, i18n } = useTranslation();

  const { user } = useOutletContext();

  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [civility, setCivility] = useState("");

  const [matricule, setMatricule] = useState("");
  const [socialNumber, setSocialNumber] = useState("");

  const [contractStart, setContractStart] = useState("");

  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const [userType, setUserType] = useState("");

  const [error, setError] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("TOKEN");

    getCompanies(token, i18n.language).then((resp) => {
      let companyOption = resp.data.data.map((company) => {
        return { value: company.id, label: company.name + " - " + company.siren };
      });
      setCompanies(companyOption);
    });
  }, []);
  const isMail = () => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  const sendInvitation = async (e) => {
    setError("");
    e.preventDefault();

    if (isMail()) {
      const payload = {
        email,
        lastName,
        firstName,
        companies: selectedCompanies,
        matricule,
        socialNumber,
        civility,
        userType: userType.value,
        contractStart,
      };

      console.log();

      createUser(localStorage.getItem("TOKEN"), payload, i18n.language)
        .then((resp) => {
          props.setUsers(resp.data.data);
          props.setFilteredUsers(resp.data.data);
          props.setIsAddingUser(false);
        })
        .catch((error) => {
          setError(error.response.data.message);
          console.log(error);
        });
    }
  };

  const options =
    user.user_type === "MANAGER"
      ? [{ value: "USER", label: t("role.user") }]
      : [{ value: "MANAGER", label: t("role.manager") }];

  const optionsCivility = [
    { value: "MAN", label: t("create.user.man") },
    {
      value: "WOMAN",
      label: t("create.user.woman"),
    },
  ];

  return (
    <div className="bg-slate-50 w-100 pl-3 pr-3 overflow-scroll">
      <Button
        className={"w-24 mb-2 h-12 border-none rounded-2xl text-base font-semibold cursor-pointer bg-logo"}
        onClick={() => props.setIsAddingUser(false)}
      >
        {"< " + t("back")}
      </Button>
      <Form className={"rounded border border-indigo-600 bg-white w-100"}>
        <div className={"p-4 w-100"}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className={"font-semibold"}>Email*</Form.Label>
            <Form.Control
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              type="email"
              placeholder={t("login.email.placeholder")}
            />
          </Form.Group>
          {user.user_type === "MANAGER" && (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className={"font-semibold"}>{t("create.user.civility")}*</Form.Label>
              <DefaultDropdown
                placeholder={t("create.user.civility.choose")}
                options={optionsCivility}
                value={civility}
                onChange={setCivility}
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className={"font-semibold"}>{t("create.user.name")}*</Form.Label>
            <Form.Control
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              required
              type="text"
              placeholder={t("create.user.name.placeholder")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className={"font-semibold"}>{t("create.user.first_name")}*</Form.Label>
            <Form.Control
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              required
              type="text"
              placeholder={t("create.user.first_name.placeholder")}
            />
          </Form.Group>
          {user.user_type === "MANAGER" && (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className={"font-semibold"}>{t("create.user.contract_start")}*</Form.Label>
              <Form.Control
                onChange={(e) => setContractStart(e.target.value)}
                value={contractStart}
                required
                type="text"
                placeholder={"25/03/1995"}
              />
            </Form.Group>
          )}
          {user.user_type === "MANAGER" && (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className={"font-semibold"}>{t("create.user.matricule")}*</Form.Label>
              <Form.Control
                onChange={(e) => setMatricule(e.target.value)}
                value={matricule}
                type="text"
                placeholder={t("create.user.matricule.placeholder")}
              />
            </Form.Group>
          )}
          {user.user_type === "MANAGER" && (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className={"font-semibold"}>{t("create.user.social.number")}</Form.Label>
              <Form.Control
                onChange={(e) => setSocialNumber(e.target.value)}
                value={socialNumber}
                type="text"
                placeholder={t("create.user.social.number.placeholder")}
              />
            </Form.Group>
          )}
          <Selector
            isMulti={user.user_type !== "MANAGER"}
            onChange={setSelectedCompanies}
            options={companies}
            name={t("settings.users.company.autorized")}
          />

          <Form.Group className={"mb-3"}>
            <Form.Label className={"font-semibold"}>{t("user.type")}*</Form.Label>
            <DefaultDropdown options={options} onChange={(value) => setUserType(value)} value={userType} />
          </Form.Group>
          <Form.Text className="text-muted text-red-600">{error}</Form.Text>
          <Button
            onClick={sendInvitation}
            className={"w-100 h-12 rounded-2xl border-none text-base font-semibold cursor-pointer bg-logo"}
            type="submit"
          >
            {t("create.user.invitation")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UserCreateUpdate;
