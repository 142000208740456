import ReactSearchBox from "react-search-box";
import Loupe from "../../_assets/loupe.png";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { getUsers } from "../../_api/admin";
import { firstLetterCapitalize } from "../../_helpers/format";
import UserCreateUpdate from "../_forms/UserCreateUpdate";
import { useTranslation } from "react-i18next";
import { Alert } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import InfosTab from "./InfosTab";
import DocumentsTab from "./DocumentsTab";
import StatsRHTab from "./StatsRH";
import DepositHistory from "./DepositHistory";
import LoadingSpinner from "../_shared/loader/loader";
import { CloudUploadOutlined, InboxOutlined } from "@ant-design/icons";
import { BulkPaySlip } from "./BulkPayslip";
import { set } from "lodash";

const Users = () => {
  const { t, i18n } = useTranslation();
  const { user } = useOutletContext();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isBulkPaySlip, setBulkPaySlip] = useState(false);

  const [tab, setTab] = useState("infos");

  const changeSelection = (_id) => {
    setIsAddingUser(false);
    setSelectedUser(users.find((user) => user.id === _id));
  };

  const onSelectSearch = (record) => {
    setSelectedUser(users.find((user) => user.id === record.item.key));
  };

  const onChangeSearch = (value) => {
    setIsAddingUser(false);
    const _users = [];
    users.map((user) => {
      if (
        user.first_name.toLowerCase().includes(value.toLowerCase()) ||
        user.last_name.toLowerCase().includes(value.toLowerCase()) ||
        (user?.internal_id && user?.internal_id.includes(value))
      ) {
        _users.push(user);
      }
    });
    setFilteredUsers(_users);
  };

  useEffect(() => {
    setIsLoading(true);
    const token = localStorage.getItem("TOKEN");
    getUsers(token, i18n.language)
      .then((resp) => {
        setUsers(resp.data.data);
        setFilteredUsers(resp.data.data);
        if (resp.data.data.length > 0) {
          setSelectedUser(resp.data.data[0]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [i18n.language, user]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className={"flex h-100"}>
          <BulkPaySlip show={isBulkPaySlip} onHide={() => setBulkPaySlip(!isBulkPaySlip)} users={users} user={user} />
          <div className={"border-x h-full min-w-[30%]"}>
            <div className={""}>
              <Button
                onClick={() => setIsAddingUser(true)}
                className={"mb-2 w-100 border-none h-10 rounded-2xl text-base font-semibold cursor-pointer bg-logo"}
              >
                {user.user_type === "DISTRIB"
                  ? t("app.users.add.client")
                  : user.user_type === "ADMIN"
                  ? t("app.users.add.manager")
                  : t("app.users.add.user")}
              </Button>

              <ReactSearchBox
                placeholder={t("app.users.search.user.placeholder")}
                data={filteredUsers}
                onSelect={(record) => onSelectSearch(record)}
                onChange={(value) => onChangeSearch(value)}
                autoFocus
                leftIcon={
                  <>
                    <img src={Loupe} width={25} height={25} alt={"Loupe"} />
                  </>
                }
                iconBoxSize="48px"
              />
            </div>
            <div className={""}>
              {filteredUsers.map((user) => {
                const nameTitle =
                  user.user_type === 'ADMIN"' ? (
                    firstLetterCapitalize(user.name)
                  ) : (
                    <>
                      {firstLetterCapitalize(user.last_name)}
                      {" " + firstLetterCapitalize(user.first_name)}
                    </>
                  );
                return (
                  <div
                    onClick={() => changeSelection(user.id)}
                    className={"h-14 p-2 text-left border-y w-100 hover:bg-white cursor-pointer"}
                  >
                    {nameTitle}
                  </div>
                );
              })}
              {filteredUsers.length === 0 && (
                <div className={"h-14 p-2 text-left border-y w-100"}>{t("document.table.no.data")}</div>
              )}
            </div>
          </div>
          {!isAddingUser && users.length > 0 && (
            <div className={"pl-3 pr-3 w-100"}>
              <div className={"h-10 flex justify-end mr-3"}>
                {/* {user.user_type === "MANAGER" && (
                  <div className={"h-10 flex justify-end"}>
                    <div
                      className={"border bg-white cursor-pointer hover:text-sky-400 flex"}
                      onClick={() => {
                        // setIsSendingToEveryOne(true);
                      }}
                    >
                      <CloudUploadOutlined className={"ml-3 mr-3  text-2xl align-middle"} />
                      <div>
                        <span className={"h-10 leading-10 pt-2 pb-2 pr-2"}>Envoyer à vos salariés</span>
                      </div>
                    </div>
                  </div>
                )} */}
                <div
                  className={"border bg-white cursor-pointer hover:text-sky-400 flex"}
                  onClick={() => {
                    setBulkPaySlip(true);
                  }}
                >
                  <InboxOutlined className={"ml-3 mr-3 pb-3 text-2xl align-middle"} />
                  <div>
                    <span className={"h-10 leading-10 pt-2 pb-2 pr-2"}>Gestion Paies</span>
                  </div>
                </div>
              </div>{" "}
              <hr className="ml-3 mr-3 mb-2" />
              {selectedUser && (
                <Tabs defaultActiveKey="infos" className="mb-3 appColor" activeKey={tab} onSelect={(k) => setTab(k)}>
                  <Tab eventKey="infos" title={t("app.users.information.title")}>
                    <InfosTab
                      user={selectedUser}
                      setUsers={setUsers}
                      setSelectedUser={setSelectedUser}
                      setFilteredUsers={setFilteredUsers}
                      tab={tab}
                      setTab={setTab}
                    />
                  </Tab>
                  {user.user_type === "MANAGER" && (
                    <Tab eventKey="documents" title={t("app.users.documents.title")}>
                      <DocumentsTab user={selectedUser} tab={tab} setTab={setTab} />
                    </Tab>
                  )}
                  {user.user_type === "MANAGER" && (
                    <Tab eventKey="stats" title={t("app.users.stats.title")}>
                      <StatsRHTab user={selectedUser} tab={tab} setTab={setTab} />
                    </Tab>
                  )}
                  {user.user_type === "MANAGER" && (
                    <Tab eventKey="history" title={"Historique des dépots"}>
                      <DepositHistory user={selectedUser} tab={tab} setTab={setTab} />
                    </Tab>
                  )}
                </Tabs>
              )}
            </div>
          )}
          {isAddingUser && (
            <UserCreateUpdate
              user={user}
              setUsers={setUsers}
              setFilteredUsers={setFilteredUsers}
              setIsAddingUser={setIsAddingUser}
            />
          )}
          {!isAddingUser && users.length === 0 && (
            <Alert className={"w-100 h-20 text-center m-3"} key={"secondary"} variant={"secondary"}>
              {t("no.users.atm")}
            </Alert>
          )}
        </div>
      )}
    </>
  );
};

export default Users;
