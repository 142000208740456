import axios from "axios";
import { privateHeader, BACK_END_URL, privateHeaderUpload, privateFileHeader, onError } from "./_shared";

export const postDocument = (token, payload, language, params = true) => {
  const formData = new FormData();
  formData.append("key", payload.key);

  if (payload.file) {
    formData.append("file", payload?.file, payload?.file.name);
    if (payload.send_to_all) {
      formData.append("send_to_all", true);
    }
  }

  if (params) {
    params =
      "?year=" +
      payload["year"]["value"] +
      "&month=" +
      payload["month"]["value"] +
      "&document_type=" +
      payload["document_type"]["value"];
  } else {
    params = "?document_type=" + payload["document_type"];
  }

  return axios
    .post(BACK_END_URL + "/s3/upload/" + payload["user"]["id"] + params, formData, {
      headers: privateHeaderUpload(token, language),
    })
    .catch(onError);
};

export const getDocuments = (token, language, prefix) => {
  let params = "";
  if (prefix) {
    params = "?key=" + prefix;
  }
  return axios
    .get(BACK_END_URL + "/s3/files" + params, {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};

export const getDocumentsByUser = (token, language, _id) => {
  return axios
    .get(BACK_END_URL + "/document/user/" + _id, {
      headers: privateHeader(token, language),
    })
    .catch(onError);
};

export const getDocument = (_id, year, month) => {
  const token = localStorage.getItem("TOKEN");

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Access-Control-Allow-Origin": "*",
      responseType: "blob",
    },
  };

  return fetch(`${BACK_END_URL}/s3/payroll/${_id}/${year}/${month}`, requestOptions)
    .then(async (res) => {
      return await res.blob();
    })
    .catch((err) => {
      return Promise.reject({ Error: "Something Went Wrong", err });
    });
};

export const renameDocument = (payload, language, _id) => {
  return axios({
    method: "put",
    url: BACK_END_URL + "/s3/file/" + _id,
    data: payload,
    headers: privateFileHeader(localStorage.getItem("TOKEN"), language),
  }).catch(onError);
};

export const moveDocument = (payload, language) => {
  return axios({
    method: "post",
    url: BACK_END_URL + "/s3/move",
    data: payload,
    headers: privateFileHeader(localStorage.getItem("TOKEN"), language),
  }).catch(onError);
};

export const deleteDocument = (payload, language, _id) => {
  return axios({
    method: "delete",
    url: BACK_END_URL + "/s3/file/" + _id,
    data: payload,
    headers: privateFileHeader(localStorage.getItem("TOKEN"), language),
  }).catch(onError);
};

export const downloadDoc = (_id, name, key) => {
  let params;
  if (key) {
    params = "?key=" + key;
  }
  const token = localStorage.getItem("TOKEN");

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Access-Control-Allow-Origin": "*",
      responseType: "blob",
    },
  };

  fetch(`${BACK_END_URL + "/s3/file/" + _id + params}`, requestOptions)
    .then(async (res) => {
      const blob = await res.blob();
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      const extension = name.split(".").slice(-1);
      link.setAttribute("download", name.split(".")[0] + "." + extension); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => {
      return Promise.reject({ Error: "Something Went Wrong", err });
    });
};

export const getPullingStatus = (payload) => {
  const token = localStorage.getItem("TOKEN");

  return axios
    .post(BACK_END_URL + "/celery", payload, {
      headers: privateHeader(token),
    })
    .catch(onError);
};
